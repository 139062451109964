import React, { useRef, useState, useEffect } from "react";
import FileAdded from "./FileAdded";
import Uploader from "./Uploader";
import { CloseOutlined } from "@ant-design/icons";
import CropModal from "./CropModal";
import { useDispatch, useSelector } from "react-redux";
import { updateImageCropAspectRatio } from "store/slices";
import { selectImageCropAspectRatio } from "store/selectors";
import notification from "antd/lib/notification";

function ImageUpload({
	onSave,
	dimensions,
	onCancel,
	extraProps,
	preImage,
	type,
	title,
	className,
	aspectRatio,
	selected,
	sizeLimit,
}) {
	const hiddenInputRef = useRef(null);
	const [fileAdded, updateFileAdded] = useState(null);
	const [deletedLocally, setDeletedLocally] = useState(false);
	const [fileAddedLocally, updateFileAddedLocally] = useState(null);
	const [imageModalFileUrl, setImageModalFileUrl] = useState(null);
	const [imageModalOPen, setImageModalOpen] = useState(false);
	const dispatch = useDispatch();
	const selectedAspectRatio = useSelector(selectImageCropAspectRatio);
	const [hovered, setHovered] = useState(false);
	const onMouseEnter = () => setHovered(true);
	const onMouseLeave = () => setHovered(false);

	useEffect(() => {
		setDeletedLocally(false);
		updateFileAddedLocally(null);
		updateFileAdded(null);
	}, [preImage]);

	useEffect(() => {
		if (selected) {
			const preview = URL.createObjectURL(selected);
			updateFileAddedLocally(preview);
		}
	}, [selected]);

	const imageUploadContainerStyles = {
		backgroundImage: `url(${preImage})`,
		backgroundSize: "contain",
		width: "285px",
		backgroundRepeat: "no-repeat",
		minHeight: `${285 / aspectRatio}px`, //`calc(285px / ${aspectRatio})
	};

	if (dimensions) {
		imageUploadContainerStyles.height = dimensions.height;
		imageUploadContainerStyles.width = dimensions.width;
		imageUploadContainerStyles.minHeight = dimensions.height;
		imageUploadContainerStyles.minWidth = dimensions.width;
	}

	const addFilesToLocalState = async (e) => {
		const files = e?.target?.files;
		const type = files?.[0]?.type?.split("/")?.[0];
		if (accept === "image/*") {
			if (type !== "image") {
				notification.error({ message: "Invalid file type" });
				return;
			}
		}

		if (files && files.length > 0) {
			const urlFile = URL.createObjectURL(files[0]);
			setImageModalFileUrl(urlFile);
			dispatch(updateImageCropAspectRatio(aspectRatio));
			setImageModalOpen(true);
		}
	};

	const handleUploaderClick = () => {
		if (hiddenInputRef.current) hiddenInputRef.current.click();
	};

	const handleCancel = () => {
		updateFileAddedLocally(null);
		updateFileAdded(null);
		setDeletedLocally(true);
		setImageModalFileUrl(null);

		if (onCancel) {
			onCancel();
		}
	};

	let accept = "image/*";

	if (type === "video") accept = "video/*";

	if (preImage) {
		const splitResult = preImage.split(".");
		let imgVidType = `${type === "video" ? "video/" : "image/"}${splitResult[splitResult.length - 1]}`;

		if (preImage.includes("stream.mux.com")) {
			imgVidType = "video/application/x-mpegURL";
		}

		if (!fileAddedLocally && !deletedLocally) {
			if (!fileAdded || (fileAdded && !fileAdded.file.includes(preImage))) {
				setDeletedLocally(false);
				updateFileAdded({
					file: preImage.includes("stream.mux.com") ? preImage : `${preImage}?${new Date().getTime()}`,
					original: {
						type: imgVidType,
					},
				});
			}
		}
	}

	return (
		<div className={`sb-image-upload ${className}`}>
			{imageModalFileUrl && selectedAspectRatio !== null && (
				<CropModal
					localFile={imageModalFileUrl}
					onSave={onSave}
					onCancel={handleCancel}
					updateFileAddedLocally={updateFileAddedLocally}
					sizeLimit={sizeLimit}
					open={imageModalOPen}
					handleClose={() => setImageModalOpen(false)}
				/>
			)}

			{fileAdded || fileAddedLocally ? (
				<div
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onTouchStart={() => setHovered(true)}
					style={type === "video" ? { position: "relative", width: "100%", height: 200 } : imageUploadContainerStyles}
					className={type === "video" ? "" : "image-upload-container"}
				>
					{hovered ? <CloseOutlined className="cancel-upload" onClick={handleCancel} /> : null}
					{fileAddedLocally && (
						<FileAdded
							type={type}
							autoplay={false}
							imageUploadedStyle={imageUploadContainerStyles}
							fileAdded={fileAddedLocally}
						/>
					)}
				</div>
			) : (
				<>
					<Uploader
						title={title}
						type={type}
						handleUploaderClick={handleUploaderClick}
						height={imageUploadContainerStyles.minHeight}
						width={imageUploadContainerStyles.width}
					/>

					<input
						onClick={(e) => (e.target.value = "")}
						accept={accept}
						ref={hiddenInputRef}
						style={{ display: "none" }}
						onChange={addFilesToLocalState}
						type="file"
					/>
				</>
			)}
		</div>
	);
}

export default ImageUpload;
