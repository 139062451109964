import React, { useState } from "react";
import "./Layout.scss";
import RightArrow from "../../../images/rightArrowNotFilled.svg";

const Layout = ({ Content, Sider, isUserCreator }) => {
	const [isSidebarOpen, setSidebarOpen] = useState(true);

	const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

	return (
		<div style={{ minHeight: !isUserCreator ? "100vh" : "calc(100vh - 56px)" }} className="workout-player-layout">
			<div className={`content ${isSidebarOpen ? "content-narrow" : "content-full"}`}>{Content}</div>

			<div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
				<div className="sider-content">{Sider}</div>
			</div>

			{/* Toggle button, positioned based on the sidebar state */}
			<div className={`toggle-btn ${isSidebarOpen ? "btn-left" : "btn-right"}`} onClick={toggleSidebar}>
				<img src={RightArrow} alt="arrow" />
			</div>
		</div>
	);
};

export default Layout;

