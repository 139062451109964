// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { Layout, Grid } from "antd";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectMemberMembershipsLoading, selectIsMemberMembershipReady, selectCreatorProduct } from "store/selectors";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { paths, noSBPaymentsPaths } from "./helpers";
import { isUserCreator } from "../../constants/ui";
import { userAtom } from "../../globalAtoms";
import SidePanel, { Sider } from "components/SidePanel";
import useLogout, { useAutoLogout } from "../../utils/useLogout";
import SwitchRoute from "./SwitchRoute";
import "./index.scss";
import { useGetDomainGymQuery } from "store/api/api";
import { Navigate, redirect, useNavigate } from "react-router";
import LoadingMask from "../../components/Common/LoadingMask/LoadingMask";
import { setLoginInProgress } from "utils/loginInProgress";

import { Default as Theme } from "themes/default";
import Header from "./Header";
import PoweredBySweatBase from "./PoweredBySweatBase";
import usePrimaryColor from "helpers/hooks/usePrimaryColor";
import alpha from "helpers/alpha";

const { Content, Footer } = Layout;

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const Dashboard = ({ history }) => {
	const navigate = useNavigate();
	const user = useRecoilValue(userAtom);
	const location = useLocation();
	const isMemberMembershipReady = useSelector(selectIsMemberMembershipReady);
	const memberMembershipsLoading = useSelector(selectMemberMembershipsLoading);
	const creatorProduct = useSelector(selectCreatorProduct);
	const [navbarState, setNavbarState] = useState("home");
	let { gym } = useParams();
	const ref = useRef(null);
	const { useBreakpoint } = Grid;
	const { lg } = useBreakpoint();
	const logoutHook = useLogout(history, gym);
	useAutoLogout(gym);
	const { data: gymData } = useGetDomainGymQuery(gym, { skip: !gym });
	const [userType, setUserType] = useState(null);
	const [selectedKey, updateSelectedKey] = useState("home");
	useEffect(() => {
		setLoginInProgress(false);
	}, []);

	const filterMenuItems = (items, gymData) => {
		const isSBPaymentsUsed = gymData?.is_used_sb_payments ?? true;
		if (!isSBPaymentsUsed) {
			return items.filter((path) => !noSBPaymentsPaths.includes(path.id));
		}
		return items;
	};

	const menuItems = useMemo(() => filterMenuItems(paths, gymData), [gymData]);

	const onMenuItemClick = (e) => {
		if (e.key === "logout") {
			return logoutHook.logout();
		}

		if (e.key === "creator-creation") {
			const urlSplit = window.location.href.split("/");
			window.location.href = `${urlSplit[0]}//${urlSplit[2]}/${e.key}`;
		}

		updateSelectedKey(e.key);
		setNavbarState(e.key);
		navigate(`${e.key === "home" ? "" : e.key}`);
	};

	useEffect(() => {
		const found = paths.filter((p) => location.pathname.includes(`/${p.id}`));

		if (found && found.length && selectedKey !== found[0].id) {
			updateSelectedKey(found[0].id);
		}
	}, [location, selectedKey]);

	useEffect(() => {
		if (gym && user && gymData) {
			//Check if the user's associations array includes the current gym
			const associationsGymIds = user?.userAssociations?.map((a) => a.gym_id);
			const association = user?.userAssociations?.find((a) => a.gym_id === gymData?.id);
			if (associationsGymIds?.includes(gymData?.id) || user?.user_type === "admin") {
				setUserType(association?.type);
			} else if (!associationsGymIds?.includes(gymData?.id) && user?.user_type !== "admin") {
				logoutHook.logout();
			}
		}
	}, [user, gym, gymData]);

	const primaryColor = usePrimaryColor();

	document.documentElement.style.setProperty("--primary-color", primaryColor);
	document.documentElement.style.setProperty("--background-color", alpha(primaryColor, 0.3));

	if (!user) {
		const loginPageUrl = `/login/${gym}`;
		return <Navigate to={loginPageUrl} />;
	}

	return (
		<Theme>
			{!userType ? (
				<LoadingMask />
			) : (
				<Layout style={{ "--primary-color": primaryColor }} className="sb-base-layout">
					{!user && redirect("/login")}
					{user &&
						!isUserCreator(userType) &&
						!isMemberMembershipReady &&
						!memberMembershipsLoading &&
						creatorProduct.price_id !== "free" &&
						redirect("/add-payment-method")}
					{isUserCreator(userType) && (
						<Sider siderRef={ref} breakpoint="lg" collapsedWidth="0" width="220" className="sb-base-layout__sider">
							<SidePanel options={menuItems} onClick={onMenuItemClick} defaultSelectedKeys={[selectedKey]} />
							<Footer className="sb-base-layout__sider__footer">
								<div className="powered-by-sweatbase">
									<PoweredBySweatBase />
								</div>
								<div className="links">
									<a href="/privacy" target="_blank" rel="noreferrer">
										Privacy
									</a>
									<span>AND</span>
									<a href="/tos" target="_blank" rel="noreferrer">
										Terms
									</a>
								</div>
							</Footer>
						</Sider>
					)}
					<Layout style={{ paddingLeft: isUserCreator(userType) && lg ? 220 : 0 }}>
						<Header user={user} userType={userType} navbarState={navbarState} />
						<Content>
							<SwitchRoute isUserCreator={isUserCreator(userType)} />
						</Content>
					</Layout>
				</Layout>
			)}
		</Theme>
	);
};

export default Dashboard;
