// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useRef } from "react";
import PropTypes from "prop-types";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { Tag } from "themes/default/_typography";
import StyledTags from "./styles";
import { Tooltip, Tag as AntTag } from "antd";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------

const TagsWithOverflow = ({ tags, className, ...other }) => {
	const tagsRef = useRef(null);
	const visibleTags = tags.slice(0, 3);
	const overflowCount = tags.length - visibleTags.length;

	return (
		<StyledTags style={{ height: 50, display: "flex" }} className="sb-tags" {...other} ref={tagsRef}>
			{visibleTags.map((tag, index) => (
				<div style={{ maxWidth: tags.length < 3 ? "100%" : undefined }} key={`tag-${index}`} className="sb-tags__tag">
					<Tooltip placement="bottom" title={tag}>
						<Tag key={index} style={{ fontSize: 12 }}>
							{tag}
						</Tag>
					</Tooltip>
				</div>
			))}
			{overflowCount > 0 && (
				<Tooltip title={tags.slice(-overflowCount).join(", ")}>
					<AntTag className="more-tag" color="orange">
						+{overflowCount} more
					</AntTag>
				</Tooltip>
			)}
		</StyledTags>
	);
};

TagsWithOverflow.propTypes = {
	tags: PropTypes.arrayOf(PropTypes.string),
	className: PropTypes.string,
};

export default TagsWithOverflow;
