import React from "react";

const OnDemandIcon = (props) => (
	<svg
		width={`${props.width ?? 6}`}
		height={`${props.height ?? 6}`}
		viewBox="0 0 5 6"
		fill={props?.fill ?? "#FF865C"}
		xmlns="http://www.w3.org/2000/svg"
		data-id="OnDemandIcon"
	>
		<path
			d="M5.0848 2.94166V3.05832C5.08463 3.11293 5.07035 3.16656 5.04334 3.21402C5.01632 3.26147 4.9775 3.30113 4.93063 3.32916L1.20146 5.49999C0.976465 5.63332 0.859798 5.63332 0.759798 5.57499L0.655631 5.51666C0.609951 5.48938 0.57188 5.45103 0.544936 5.40515C0.517991 5.35927 0.50304 5.30734 0.501465 5.25416V0.745822C0.501631 0.691218 0.515913 0.637584 0.542926 0.590128C0.569939 0.542673 0.608764 0.50301 0.655631 0.474989L0.759798 0.416656C0.859798 0.358322 0.976465 0.358322 1.26813 0.529156L4.93063 2.67082C4.9775 2.69884 5.01632 2.73851 5.04334 2.78596C5.07035 2.83342 5.08463 2.88705 5.0848 2.94166Z"
			fill={props?.fill ?? "#FF865C"}
		/>
	</svg>
);

export default OnDemandIcon;

