import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { useSetRecoilState } from "recoil";
import { Modal, notification, Tooltip, Form, Button as AntButton } from "antd";

import ajaxRoutes from "utils/ajaxRoutes";
import Ajax from "utils/api";
import {
	useGetCategoriesQuery,
	useGetDomainGymQuery,
	useGetS3SignedUrlMutation,
	useGetGymWorkoutsQuery,
} from "store/api/api";
import { categoriesAtom } from "globalAtoms";
import { maxImageAssetSize, imageSizeLimitError } from "helpers/constants";

import ImageUpload from "components/ImageUpload";
import { Paragraph3 } from "themes/default/_typography";
import { Button } from "components/Common/Button";
import { CloseIcon } from "components/Icons";
import { FormInput } from "components/Common/Form/FormInput";

const CategoryFormModal = ({ open, gymId, setShowCreateCategoryModal, categoryToEdit }) => {
	const { gym } = useParams();
	const [form] = Form.useForm();

	const setCategories = useSetRecoilState(categoriesAtom);

	const [getS3SignedUrlTrigger] = useGetS3SignedUrlMutation();
	const domainGymResponse = useGetDomainGymQuery(gym);

	const domainGymId = domainGymResponse.data?.id;
	const categoriesResponse = useGetCategoriesQuery(domainGymId, { skip: !domainGymResponse.data });
	const getWorkoutsResponse = useGetGymWorkoutsQuery({ gym_id: domainGymId }, { skip: !domainGymResponse.data });

	const [newImageUrl, setNewImageUrl] = useState("");
	const [uploading, setUploading] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);

	useEffect(() => {
		if (categoryToEdit) {
			setNewImageUrl(categoryToEdit.image_url);
		}
	}, [categoryToEdit]);

	useEffect(() => {
		if (categoriesResponse.data) {
			setCategories(categoriesResponse.data.categories);
		}
	}, [categoriesResponse, setCategories]);

	const resetForm = () => {
		setNewImageUrl("");
		form.resetFields();
	};

	const onSaveThumbnail = async (file) => {
		if (file) {
			setUploading(true);
			const mimeMap = {
				"image/jpeg": "jpg",
				"image/png": "png",
			};
			const fileType = mimeMap[file.type];
			const s3KeyThumbnail = `assets/${domainGymId}/category-${Date.now()}.${fileType}`;
			const s3SignedResponse = await getS3SignedUrlTrigger({ key: s3KeyThumbnail, type: "image" });
			await uploadAsset(s3SignedResponse.data.response, file);
			const fullS3ThumbNail = `${s3SignedResponse.data.response.url}/${s3KeyThumbnail}`;
			setNewImageUrl(fullS3ThumbNail);
			setUploading(false);
		}
	};

	const uploadAsset = async (response, thumbnailFile) => {
		const formData = new FormData();
		for (const field in response.fields) {
			formData.append(field, response.fields[field]);
		}
		formData.append("file", thumbnailFile);
		try {
			await axios.post(response.url, formData, { headers: { "Content-Type": "multipart/form-data" } });
		} catch (error) {
			console.debug("error", error);
		}
	};

	const handleSubmit = async (values) => {
		if (uploading) return;

		const requestData = {
			gym_id: gymId,
			name: values.name.trim(),
			image_url: newImageUrl,
		};

		const response = categoryToEdit
			? await Ajax.call(ajaxRoutes.EDIT_CATEGORY, "put", { ...requestData, id: categoryToEdit.id }, (error) => {
					notification.error({ message: error?.data?.message || "There was a problem updating the category" });
			  })
			: await Ajax.call(ajaxRoutes.CREATE_CATEGORY, "post", requestData, (error) => {
					notification.error({ message: error?.data?.message || "There was a problem creating the category" });
			  });

		if (response?.categories || response?.message === "Category Created") {
			notification.success({ message: response.message || "Category edited successfully" });
			categoriesResponse.refetch();
			resetForm();
			setShowCreateCategoryModal(false);
		}
	};

	const handleDeleteCategory = async () => {
		const { id, gym_id } = categoryToEdit;
		try {
			setConfirmOpen(false);
			setDeleteLoading(true);
			const response = await Ajax.call(ajaxRoutes.DELETE_CATEGORY, "put", {
				gym_id: gym_id,
				category_id: id,
			});
			await getWorkoutsResponse.refetch();
			if (response.categories) {
				notification.success({ message: "Category deleted successfully" });
				categoriesResponse.refetch();
			} else {
				notification.error({ message: "There was a problem deleting the category" });
			}
			setShowCreateCategoryModal(false);
		} catch (error) {
			console.error(error);
		} finally {
			setDeleteLoading(false);
		}
	};

	return open ? (
		<>
			<Modal
				title="Confirm"
				open={confirmOpen}
				onOk={handleDeleteCategory}
				onCancel={() => setConfirmOpen(false)}
				okText="Yes"
				cancelText="Go Back"
				maskClosable={false}
				destroyOnClose
				footer={
					<div className="sb-confirm-form__actions">
						<AntButton onClick={() => setConfirmOpen(false)}>Go Back</AntButton>
						<AntButton onClick={handleDeleteCategory} type="primary">
							Yes
						</AntButton>
					</div>
				}
			>
				Are you sure you want to delete this category?
			</Modal>

			<Modal
				width={500}
				className="sb-modal"
				title={categoryToEdit ? "Edit Category" : "New Category"}
				open={open}
				closeIcon={<CloseIcon data-id="close-category-modal" />}
				onCancel={() => {
					resetForm();
					setShowCreateCategoryModal(false);
				}}
				footer={null}
				maskClosable={false}
			>
				<Form
					layout="vertical"
					form={form}
					onFinish={handleSubmit}
					initialValues={{
						name: categoryToEdit?.name,
					}}
				>
					<div className="sb-workout-details-form__form__field">
						<FormInput
							name="name"
							placeholder="Name"
							label="Category Name"
							rules={[
								{ required: true, message: "This field is required" },
								() => ({
									validator(_, value) {
										if (!value) {
											return Promise.resolve();
										}
										if (value.length > 30) {
											return Promise.reject(new Error("Category name must be less than 30 characters"));
										}
										return Promise.resolve();
									},
								}),
							]}
						/>
					</div>

					<Form.Item required={false}>
						<ImageUpload
							className="sb-edit-category-form__upload__input"
							aspectRatio={300 / 180}
							dimensions={{ width: "300px", height: "180px" }}
							onSave={onSaveThumbnail}
							type="image"
							preImage={newImageUrl}
							title="Upload Thumbnail"
							onCancel={() => setNewImageUrl("")}
							sizeLimit={{
								limit: maxImageAssetSize,
								error: imageSizeLimitError,
							}}
						/>
					</Form.Item>

					<div key="create" className="sb-create-category-modal__footer">
						{categoryToEdit && (
							<Button
								type="button"
								loading={deleteLoading}
								className="primary-outlined primary-outlined--delete"
								onClick={() => setConfirmOpen(true)}
							>
								<Paragraph3>Delete</Paragraph3>
							</Button>
						)}

						<Tooltip title={uploading ? "Upload in progress, please wait" : ""}>
							<Button type="submit" loading={uploading} className="primary-filled">
								{categoryToEdit ? "Save Changes" : "Create Category"}
							</Button>
						</Tooltip>
					</div>
				</Form>
			</Modal>
		</>
	) : null;
};

export default CategoryFormModal;

