// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import { Image } from "antd";
import { useEffect, useState } from "react";
// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { LoadingMask } from "components/Common/LoadingMask";
import { CheckIcon } from "components/Icons";
import { Heading14 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Styles and Utils
// -----------------------------------------------------------------------------
import { bem } from "helpers/Bem";
import StyledGenreSelector from "./styles";
import { useGetGenresQuery } from "store/api/api";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const GenreSelector = ({ setSelectedGenre, initialSelectedGenreId }) => {
	const { data, isLoading: genresLoading } = useGetGenresQuery();
	const initialSelected = data?.genres?.find(({ id }) => id === initialSelectedGenreId) ?? {};
	const [localSelectedGenre, setLocalSelectedGenre] = useState(initialSelected);
	const onGenreClick = (genre) => {
		if (genre.id === localSelectedGenre?.id) {
			setLocalSelectedGenre({});
			setSelectedGenre({});
		} else {
			setLocalSelectedGenre(genre);
			setSelectedGenre(genre);
		}
	};

	useEffect(() => {
		if (initialSelectedGenreId !== undefined) {
			const initialSelected = data?.genres?.find(({ id }) => id === initialSelectedGenreId) ?? {};
			setLocalSelectedGenre(initialSelected);
		}
	}, [data?.genres, initialSelectedGenreId]);

	return (
		<StyledGenreSelector>
			{genresLoading && <LoadingMask />}
			{!genresLoading && (
				<div className="sb-genre-selector__genres">
					{data?.genres?.length > 0 &&
						data?.genres.map(({ image_url, title, id }) => (
							<div
								className={bem`sb-genre-selector__genres__genre--${{
									selected: localSelectedGenre.id === id,
								}}`}
								key={`genre-${id}`}
								onClick={() => onGenreClick({ title, id })}
								data-id="sb-genre-selector-box"
							>
								<div className="sb-genre-selector__genres__genre__inner">
									<div className="sb-genre-selector__genres__genre__inner__front">
										<Image preview={false} src={image_url} />
									</div>
									<div className="sb-genre-selector__genres__genre__inner__back" data-id="lblGenreTitle">
										<Heading14>{title}</Heading14>
									</div>
									<div className="sb-genre-selector__genres__genre__inner__check">
										<CheckIcon />
									</div>
								</div>
							</div>
						))}
				</div>
			)}
		</StyledGenreSelector>
	);
};

export default GenreSelector;

