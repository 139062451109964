// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React, { useCallback, useState } from "react";
import Modal from "antd/lib/modal";
import MuxPlayer from "@mux/mux-player-react";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { ScheduleLiveForm } from "components/Forms/Workout/ScheduleLiveForm";
import { WorkoutDetailsForm } from "components/Forms/Workout/WorkoutDetailsForm";
import { WorkoutMusicForm } from "components/Forms/Workout/WorkoutMusicForm";
import { ReleaseScheduleForm } from "components/Forms/Workout/ReleaseScheduleForm";
import { BarChartIcon, CalendarIcon, CalendarLiveIcon, CloseIcon, DumbellIcon, TimerIcon } from "components/Icons";
import { Steps } from "components/Common/Steps";
import { Heading10, Paragraph12 } from "themes/default/_typography";

// -----------------------------------------------------------------------------
// Styles and utils
// -----------------------------------------------------------------------------
import StyledJoinLiveStreamModal from "./styles";
import styled from "styled-components";
import { MusicIcon } from "components/Icons/MusicIcon";

const SignUpButton = styled.button`
	/* Adapt the colors based on primary prop */
	cursor: pointer;
	color: white;
	padding: 10px;
	margin-bottom: 0;
	width: 100%;
	background: #000000;
	border-radius: 100px;
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	letter-spacing: 2px;
	text-transform: uppercase;
	border: none;
	align-items: center;
	justify-content: center;
	display: flex;
	gap: 8px;
	&:hover {
		opacity: 0.8;
	}
`;

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const JoinLiveStreamModal = ({ open, setOpen, playbackId }) => {
	const handleCloseModal = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	const handleSignUp = async () => {
		setOpen(false);
	};

	return (
		<Modal
			width={656}
			closeIcon={<CloseIcon data-id="close-schedule-live-modal" />}
			open={open}
			onCancel={handleCloseModal}
			footer={null}
			maskClosable={false}
		>
			<StyledJoinLiveStreamModal data-id="schedule-live-modal" className="sb-schedule-live-modal">
				<div className="sb-schedule-live-modal__header">
					<h1>Abs, Legs, and Glutes</h1>
					<h2>Coach Warren</h2>
					<p>
						Crush your workout with body weight exercises that help improve balance, flexibility, and strength without
						gym machines or equipment. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna sagittis a mi
						vivamus facilisis nisl vel.
					</p>
				</div>
				<div className="sb-schedule-live-modal__divider" />
				<div className="sb-schedule-live-modal__body">
					<MuxPlayer
						streamType="on-demand"
						playbackId={playbackId}
						metadata={{
							video_id: "playbackId",
							video_title: "Test video title",
							viewer_user_id: "user-id-007",
						}}
					/>
				</div>
			</StyledJoinLiveStreamModal>
		</Modal>
	);
};

export default JoinLiveStreamModal;

