import React, { useState, cloneElement } from "react";
import { Dropdown as AntDropDown } from "antd";

const Dropdown = (props) => {
	const [visible, setVisible] = useState(false);

	const handleClick = (e) => {
		e.stopPropagation();
		setVisible(!visible);
	};

	const onOpenChange = (status, trigger) => {
		if (trigger.source !== "menu") {
			setVisible(status);
		}
	};

	const childrenWithClick = props.children ? cloneElement(props.children, { onClick: handleClick }) : null;
	return (
		<AntDropDown {...props} open={visible} onOpenChange={onOpenChange} overlayClassName="dropdown-no-close">
			{childrenWithClick}
		</AntDropDown>
	);
};

export default Dropdown;

