import styled from "styled-components";

const StyledWorkoutCard = styled.div`
	width: 302px;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 12px;

	.sb-workout-card {
		&__inner {
			border-radius: 8px;

			&__header-section {
				color: red;
				height: 180px;
				background-size: cover;
				background-position: center;
				background-image: ${({ headerImage }) => `url(${headerImage})`};
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
			}

			&__body-section {
				padding: 16px;
				display: flex;
				flex-direction: column;
				gap: 8px;
				text-overflow: ellipsis;
				width: 302px;
				overflow: hidden;
				.title {
					height: 32px;
					line-clamp: 2;
				}

				p {
					width: 100%;
					overflow: hidden;
					margin-bottom: 0;
					text-overflow: ellipsis;
					font-weight: 600;
				}

				&__categories {
					height: 52px;
					overflow: hidden;
				}
			}
		}
	}

	.ant-card-body {
		padding: 0;
	}
`;

export default StyledWorkoutCard;
