import styled from "styled-components";

const StyledPricingPlanPage = styled.div`
	position: relative;
	height: 100%;

	.sb-pricing-plan-page {
		display: flex;
		flex-direction: row;
		height: 100%;
		align-items: flex-start;
		justify-content: flex-start;
		padding: 32px;
		gap: 32px;
		flex: 1;
		width: 100%;

		&__left {
			padding-top: 16px;
			min-width: 225px;
			height: 168px;
			border-radius: 6px;
			background: #feffff;
			border: 1px solid #e3e5e7;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;

			&__text {
				color: #000000;
				font-size: 16px;
				font-weight: 700;
				padding-left: 16px;
				margin-bottom: 16px;
			}
			&__divider {
				height: 1px;

				width: 100%;
				background: #e3e5e7;
			}
			&__tab-text {
				font-weight: 700;
				font-size: 12px;
				line-height: 12px;

				display: flex;
				align-items: center;
				text-transform: uppercase;

				color: #4f5661;
				margin-bottom: 0px;
			}
			&__tab-text-container {
				width: 100%;
				display: flex;
				align-items: center;
				text-transform: uppercase;
				padding: 16px;
				padding-top: 8px;
				padding-bottom: 8px;
				cursor: pointer;
				&__selected {
					background: #f4f4f6;
					border-left: 3px solid ${(props) => props.primaryColor};
					width: 100%;
					display: flex;
					align-items: center;
					text-transform: uppercase;
					padding: 16px;
					padding-top: 8px;
					padding-bottom: 8px;
					cursor: pointer;
				}
			}
		}
		&__right {
			padding: 80px;

			background: #feffff;
			/* Secondary/Light-1 - #E6E6EB */
			border: 1px solid #e3e5e7;
			border-radius: 6px;
			flex-grow: 1;
			&__notification {
				background: #ffffff;

				border: 1px solid #e3e5e7;
				border-radius: 5px;

				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				padding: 24px;
				margin-bottom: 24px;
				margin-top: 24px;

				&__text {
					font-size: 12px;
					line-height: 16px;
					color: #000000;
					margin-bottom: 0px;
					&__selected {
						font-weight: 700;
					}
				}
			}

			&__heading {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				gap: 16px;
				margin-bottom: 60px;

				&__button {
					margin-top: 32px;
				}
			}
		}
		@media (max-width: 968px) {
			flex-wrap: wrap;
			&__left {
				width: 100%;
			}
			&__right {
				padding: 0;
			}
		}
	}
`;

export default StyledPricingPlanPage;

