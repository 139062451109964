import React, { useState, useMemo, useEffect } from "react";
import { Alert, Button } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { stripeConnectedAccountStatuses, stripeConnectedAccountStatusMessages } from "helpers/constants";
import { useParams } from "react-router";
import {
	useStripeCreateOnboardingLinkMutation,
	useGetDomainGymQuery,
	useStripeGetCustomerConnectedExternalAccountsMutation,
} from "store/api/api";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../globalAtoms";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

const StripeAlerts = () => {
	const [gymStripeInfo, setGymStripeInfo] = useState(null);
	const [createOnboardingLinkTrigger] = useStripeCreateOnboardingLinkMutation();
	const [connectedAccountId, setConnectedAccountId] = useState(null);
	const user = useRecoilValue(userAtom);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const isUsedSBPayments = !!(domainGymResponse?.data?.is_used_sb_payments ?? 1);
	const [userPayoutsEnabled, setUserPayoutsEnabled] = useState(undefined);
	const [getConnectedAccountsTrigger] = useStripeGetCustomerConnectedExternalAccountsMutation();
	const fetchCustomerConnectedExternalAccounts = async () => {
		try {
			const bankAccountsRes = await getConnectedAccountsTrigger({ account_id: connectedAccountId }).unwrap();
			setUserPayoutsEnabled(bankAccountsRes?.data?.length > 0);
		} catch (error) {
			setUserPayoutsEnabled(false);
		}
	};
	useEffect(() => {
		if (connectedAccountId) {
			fetchCustomerConnectedExternalAccounts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectedAccountId]);

	useEffect(() => {
		if (user?.gym?.stripe_info?.stripe_connected_account_id) {
			setConnectedAccountId(user.gym.stripe_info.stripe_connected_account_id);

			const stripeInfo = user?.gym?.stripe_info;
			const formattedStripeInfo = {
				...stripeInfo,
				current_deadline: stripeInfo.current_deadline
					? dayjs.unix(stripeInfo.current_deadline).format("MMMM D, YYYY")
					: stripeInfo.current_deadline,
				errors: stripeInfo.errors ? JSON.parse(stripeInfo.errors) : stripeInfo.errors,
			};
			setGymStripeInfo(formattedStripeInfo);
		}
	}, [user]);

	const showStripeError = useMemo(
		() =>
			isUsedSBPayments &&
			userPayoutsEnabled &&
			gymStripeInfo &&
			gymStripeInfo?.status !== stripeConnectedAccountStatuses.ACTIVE,
		[isUsedSBPayments, userPayoutsEnabled, gymStripeInfo]
	);
	const handleUpdateStripeAccount = async () => {
		try {
			const getConnectedAcct = await createOnboardingLinkTrigger({
				return_url: window.location.href,
				account_id: connectedAccountId,
				type: userPayoutsEnabled ? "account_update" : "account_onboarding",
			}).unwrap();
			window.location.href = getConnectedAcct.url;
		} catch (error) {
			console.error("ERROR: ", error);
		}
	};
	if (!showStripeError) return null;
	return (
		<Alert
			type="error"
			className="sb-alert"
			message={stripeConnectedAccountStatusMessages[gymStripeInfo?.status]}
			icon={<CloseCircleOutlined />}
			description={
				<>
					{gymStripeInfo?.disabled_reason}
					{gymStripeInfo?.current_deadline && (
						<p>
							You need to provide details for Stripe up to{" "}
							<span style={{ fontWeight: "bold" }}>{gymStripeInfo?.current_deadline}</span>
						</p>
					)}
					{gymStripeInfo?.errors?.length > 0 && (
						<div>
							{gymStripeInfo?.errors?.map?.((error, index) => (
								<p key={index}>{error}</p>
							))}
						</div>
					)}
					<Button className="sb-button-alert" type="primary" onClick={handleUpdateStripeAccount}>
						Update Stripe Information
					</Button>
				</>
			}
			showIcon
		/>
	);
};

export default StripeAlerts;

