// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import message from "antd/lib/message";
import { Form, Button } from "antd";
import notification from "antd/lib/notification";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import Logo from "../../images/logo.svg";
import { useConfirmResetPasswordMutation, useCheckResetPasswordTokenMutation } from "store/api/api";
import InputField from "components/UI/Form/InputField";
import { verifyPasswordNew } from "helpers/Form/commonFormValidations";
import { isTokenExpired } from "helpers/auth";
import "./styles.scss";
// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
function ResetPassword() {
	const navigate = useNavigate();
	const { userId, token, subdomain } = useParams();

	const [confirmResetPasswordTrigger] = useConfirmResetPasswordMutation();
	const [checkResetPasswordTokenTrigger] = useCheckResetPasswordTokenMutation();
	const [form] = Form.useForm();

	const [password, updatePassword] = useState("");
	const [passConfirm, updatePassConfirm] = useState("");
	const [isTokenValid, setIsTokenValid] = useState(true);

	const handleChange = (e) => {
		updatePassword(e?.target?.value);
	};

	useEffect(() => {
		(async () => {
			const location = window.location;
			const url = new URL(`/error`, location.origin);
			url.searchParams.append("message", "Link has expired");
			url.searchParams.append("subdomain", subdomain);
			try {
				const response = await checkResetPasswordTokenTrigger({ token }).unwrap();
				if (!response?.is_token_valid) {
					return navigate(url.pathname + url.search);
				}
			} catch (error) {
				return navigate(url.pathname + url.search);
			}
		})();
	}, [checkResetPasswordTokenTrigger, subdomain, token, navigate]);
	const submit = async () => {
		if (password === passConfirm && !verifyPasswordNew(password)) {
			try {
				await confirmResetPasswordTrigger({ password, token, user_id: userId }).unwrap();
				message.success("Password reset successfully! You will be redirected to the login page shortly.");

				setTimeout(() => {
					navigate(`/login/${subdomain}`);
				}, 3000);
			} catch (error) {
				if (error?.data?.message === "Password reset link expired!") {
					notification.error({
						message: "Password reset link expired!",
					});
					setIsTokenValid(false);
				} else {
					notification.error({
						message: error?.data?.message || "An unknown error occurred",
					});
				}
			}
		}
	};
	useEffect(() => {}, []);
	if (!isTokenValid || (token && isTokenExpired(token))) {
		message.error("Password reset link expired!");
		const url = new URL(`/error`, window.location.origin);
		url.searchParams.append("message", "Link has expired");
		url.searchParams.append("subdomain", subdomain);
		return <Navigate to={url.pathname + url.search} />;
	}

	return (
		<div className="sb-login">
			<div className="sb-login__container">
				<div className="sb-login__container__form-container">
					<div className="sb-login__container__form-container__header">
						<img className="sb-login__container__form-container__header__image" src={Logo} alt="logo" />
						<h1 className="sb-login__container__form-container__header__title">Reset Password</h1>
					</div>
					<div className="sb-login__container__form-container__form">
						<div className="sb-signup-form">
							<Form name="basic" layout="vertical" autoComplete="off" form={form}>
								<Form.Item
									label="Password"
									name="password"
									hasFeedback
									required={false}
									rules={[
										{
											required: true,
											message: "",
											pattern: /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9!@#$%^&*()~¥=_+}{":;'?/>.<,`\-\|\[\]]{8,50}$/,
										},
										() => ({
											validator(_, value) {
												if (!value || !verifyPasswordNew(value)) {
													return Promise.resolve();
												}
												return Promise.reject(new Error(verifyPasswordNew(value)));
											},
										}),
									]}
								>
									<InputField value={password} onChange={handleChange} placeholder="password" type="password" data-id="txtPassword"/>
								</Form.Item>

								<Form.Item
									label="Password Confirmation"
									name="password-confirmation"
									hasFeedback
									required={false}
									validateStatus={
										form.getFieldValue("password-confirmation") &&
										form.getFieldValue("password") !== form.getFieldValue("password-confirmation")
											? "error"
											: undefined
									}
									help={
										form.getFieldValue("password-confirmation") &&
										form.getFieldValue("password") !== form.getFieldValue("password-confirmation")
											? "The two passwords that you entered do not match!"
											: ""
									}
									rules={[
										{
											required: true,
											message: "Please confirm your new password!",
										},
									]}
								>
									<div className="input-field-wrapper">
										<InputField
											type="password"
											value={passConfirm}
											onChange={(e) => updatePassConfirm(e?.target?.value)}
											placeholder="confirm password"
											data-id="txtConfirmPassword"
										/>
									</div>
								</Form.Item>

								<Form.Item required={false}>
									<Button
										style={{ width: "100%" }}
										disabled={!isTokenValid}
										type="primary"
										htmlType="button"
										onClick={submit}
										data-id="btnSubmit"
									>
										Submit
									</Button>
								</Form.Item>
							</Form>
						</div>
					</div>
				</div>
				<div className="sb-login__container__image-container">
					<div className="sb-login__container__image-container__inner">
						<div className="sb-login__container__image-container__inner__image" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default ResetPassword;
