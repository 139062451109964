// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// -----------------------------------------------------------------------------
// Components and pages
// -----------------------------------------------------------------------------
import ResetPassword from "components/ResetPassword/ResetPassword";
import EarningsPage from "pages/Dashboard/Earnings";
import { ClientMembership } from "pages/ClientPortal/ClientMembership";
import { AccountSettings } from "pages/ClientPortal/AccountSettings";
import ViewWorkout from "pages/ClientPortal/ViewWorkout";
import MyGym from "pages/ClientPortal/MyGym";
import OnDemand from "pages/ClientPortal/OnDemand";
import Live from "pages/ClientPortal/Live";
import Profile from "pages/ClientPortal/Profile";
import Activity from "pages/ClientPortal/Activity";
import Home from "./Home";
import Workouts from "./Workouts";
import Members from "./Members";
import Analytics from "./Analytics";
import Settings from "./Settings";
import { PricingPlan } from "./PricingPlan";
import { PlanSelection } from "components/Payments/PlanSelection";
import ConfirmEmailChange from "components/ConfirmEmailChange";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const SwitchRoute = ({ isUserCreator }) => {
	return (
		<div className="sb-switch-route">
			<Routes>
				<Route exact path={"/"} element={isUserCreator ? <Home /> : <OnDemand />} />

				{/* Creator Portal */}
				<>
					<Route path={`workouts`} element={<Workouts />} />
					<Route path={`members`} element={<Members />} />
					<Route path={`earnings`} element={<EarningsPage />} />
					{/* <Route path={`payment-settings`} element={<PaymentSettings />} /> */}
					<Route path={`pricing-plan`} element={<PricingPlan />} />
					<Route path={`analytics`} element={<Analytics />} />
					<Route path={`settings`} element={<Settings />} />
					<Route path={`select-plan`} element={<PlanSelection />} />
				</>

				{/* Consumer Portal */}
				<Route path={`view-workout/:workoutID`} element={<ViewWorkout isUserCreator={isUserCreator} />} />
				<Route path={`view-live-workout/:workoutID`} element={<ViewWorkout isUserCreator={isUserCreator} />} />

				<Route path={`on-demand`} element={<OnDemand />} />
				<Route path={`live`} element={<Live />} />
				<Route path={`profile`} element={<Profile />} />
				<Route path={`account-settings`} element={<AccountSettings />} />
				<Route path={`consumer-membership`} element={<ClientMembership />} />
				<Route path={`reset-password/:token`} element={<ResetPassword />} />
				<Route path={`my-gym`} element={<MyGym />} />
				<Route path={`activity`} element={<Activity />} />
				<Route path={`confirm-email-change/:userId/:token`} element={<ConfirmEmailChange />} />

				{/* Creator Portal */}
				<Route path={`workouts`} element={<Workouts />} />
				<Route path={`members`} element={<Members />} />
				<Route path={`earnings`} element={<EarningsPage />} />
				<Route path={`consumer`} element={<OnDemand />} />
				<Route path={`consumer/on-demand`} element={<OnDemand />} />
				<Route path={`consumer/live`} element={<Live />} />
				{/* <Route path={`payment-settings`} element={<PaymentSettings />} /> */}
				<Route path={`pricing-plan`} element={<PricingPlan />} />
				<Route path={`analytics`} element={<Analytics />} />
				<Route path={`settings`} element={<Settings />} />

				<Route path="*" element={<Navigate to="/404" />} />
			</Routes>
		</div>
	);
};

export default SwitchRoute;
