// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Heading10, Paragraph22 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledMemeberPayments from "./styles";
import {
	useCancelSubscriptionMutation,
	useGetCurrentUserQuery,
	useGetDomainGymQuery,
	useStripeCheckSubscriptionStatusMutation,
} from "store/api/api";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";
import { Button } from "components/Common/Button";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const MemeberPayments = () => {
	const token = localStorage.getItem("creator_jwt_token");
	const split = token.split(".")[1];
	const formatted = JSON.parse(atob(split));
	const JWTID = formatted.id;
	let { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse?.data?.id;
	const user = useRecoilValue(userAtom);
	const userAssociations = user?.userAssociations;
	const associationInUse = userAssociations?.find((a) => a.gym_id === domainGymId) || {};
	const { subscription_id, subscription_status } = associationInUse;
	const [cancelling, setCancelling] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const { data: userData, refetch: refetchUserData } = useGetCurrentUserQuery({ user_id: JWTID });

	const [cancelSubscriptionTrigger] = useCancelSubscriptionMutation();
	const [checkSubscriptionStatusTrigger] = useStripeCheckSubscriptionStatusMutation();

	useEffect(() => {
		const checkSubscriptionStatus = async () => {
			if (subscription_id) {
				const checkSubscriptionStatusResponse = await checkSubscriptionStatusTrigger({
					subscription_id,
				});

				const subscriptionStatus = checkSubscriptionStatusResponse?.data?.status;

				if (subscriptionStatus === "canceled" && subscription_status === "ACTIVE") {
					await cancelSubscriptionTrigger({
						subscription_id: subscription_id,
						customer_id: userData?.customer_id,
						gym_id: domainGymId,
					}).unwrap();

					refetchUserData();
					window.location.reload();
				}
			}
		};

		checkSubscriptionStatus();
	}, [subscription_id, checkSubscriptionStatusTrigger]);

	const cancelSubscription = async () => {
		setShowConfirmation(true);
	};

	const confirmCancel = async () => {
		setCancelling(true);
		try {
			await cancelSubscriptionTrigger({
				subscription_id: subscription_id,
				customer_id: userData?.customer_id,
				gym_id: domainGymId,
			}).unwrap();

			refetchUserData();
			window.location.reload();
		} catch (error) {
			console.log("unable to cancel");
		}
		setCancelling(false);
	};

	return (
		<StyledMemeberPayments className="sb-member-payments">
			<div className="sb-member-payments__header">
				{(domainGymResponse?.data?.light_logo || domainGymResponse?.data?.dark_logo) && (
					<img
						className="sb-member-payments__header__image"
						src={domainGymResponse?.data?.light_logo || domainGymResponse?.data?.dark_logo}
						alt="logo"
					/>
				)}
				<Heading10 className="sb-member-payments__header__title">Membership Info</Heading10>
				<Paragraph22 className="sb-member-payments__header__subtitle">
					Your Current Subscription Status: {subscription_status}
				</Paragraph22>
				{subscription_status === "ACTIVE" && !showConfirmation && (
					<Button onClick={cancelSubscription} uppercase style={{ backgroundColor: "#eb5757", fontWeight: 700 }}>
						Cancel Subscription
					</Button>
				)}
				{subscription_status === "ACTIVE" && showConfirmation && (
					<>
						<Heading10 className="sb-member-payments__header__subtitle">
							Are you sure you want to cancel your subscription?
						</Heading10>
						<Paragraph22 className="sb-member-payments__header__subtitle">
							You will retain access to this gym's library of on-demand videos and live-streams until the end of your
							current billing period
						</Paragraph22>
						<Button
							onClick={() => setShowConfirmation(false)}
							uppercase
							style={{ fontWeight: 700, marginRight: "16px", marginBottom: "16px" }}
						>
							No, Keep My Subscription
						</Button>
						<Button
							loading={cancelling}
							onClick={cancelling ? null : confirmCancel}
							uppercase
							style={{
								backgroundColor: "#eb5757",
								fontWeight: 700,
								opacity: cancelling ? 0.5 : 1,
								marginBottom: "16px",
							}}
						>
							Yes, I want to Cancel
						</Button>
					</>
				)}
			</div>
		</StyledMemeberPayments>
	);
};

export default MemeberPayments;

