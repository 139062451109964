import { Form, Input, Button, notification } from "antd";
import { useEffect, useState } from "react";
import "./overrides.scss";
import usePrimaryColor from "helpers/hooks/usePrimaryColor";
import { LockOutlined } from "@ant-design/icons";
import { verifyPasswordNew } from "helpers/Form/commonFormValidations";
import { useUpdatePasswordMutation } from "store/api/api";

export default function PasswordReset({ onCancel }) {
	const primaryColor = usePrimaryColor();
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setconfirmPassword] = useState("");
	const [updatePasswordTrigger, updatePasswordResponse] = useUpdatePasswordMutation();
	const [form] = Form.useForm();

	const onFinish = async () => {
		try {
			const { password_confirmation, ...values } = form.getFieldsValue();
			await updatePasswordTrigger(values).unwrap();
			notification.success({ message: "Password  changed successfully" });
			onCancel();
		} catch (error) {
			notification.error({ message: error?.data?.message || "An error occurred when changing password" });
		}
	};
	useEffect(() => {
		if (confirmPassword) {
			form.validateFields(["password_confirmation"]);
		}
	}, [form, confirmPassword, newPassword]);
	useEffect(() => {
		if (newPassword) {
			form.validateFields(["new_password"]);
		}
	}, [form, currentPassword, newPassword]);
	return (
		<Form onFinish={onFinish} form={form} name="password-reset">
			<Form.Item
				style={{ display: "block", "--primary-color": primaryColor }}
				layout="vertical"
				label="Current Password"
				name="current_password"
				required={false}
				rules={[
					{
						required: true,
						message: "Current Password is required",
					},
				]}
			>
				<Input.Password
					onChange={(e) => setCurrentPassword(e.target.value)}
					prefix={<LockOutlined />}
					placeholder="Enter Current Password"
					name="currentPassword"
					data-id="txtCurrentPassword"
				/>
			</Form.Item>
			<Form.Item
				required={false}
				rules={[
					{
						required: true,
						message: "New Password is required",
					},
					() => ({
						validator(_, value) {
							if (value && form.getFieldValue("current_password") === value) {
								return Promise.reject(new Error("The Curernt Password and New Password should not be same"));
							}
							if (!value || !verifyPasswordNew(value)) {
								return Promise.resolve();
							}
							return Promise.reject(new Error(verifyPasswordNew(value)));
						},
					}),
				]}
				layout="vertical"
				label="New Password"
				name="new_password"
			>
				<Input.Password
					onChange={(e) => setNewPassword(e.target.value)}
					prefix={<LockOutlined />}
					placeholder="New Password"
					name="newPassword"
					data-id="txtNewPassword"
				/>
			</Form.Item>
			<Form.Item
				required={false}
				layout="vertical"
				label="Confirm Password"
				name="password_confirmation"
				rules={[
					{
						required: true,
						message: "Please confirm your new password!",
					},
					() => ({
						validator(_, value) {
							if (value && form.getFieldValue("new_password") !== value) {
								return Promise.reject(new Error("The two passwords that you entered do not match!"));
							}
							return Promise.resolve();
						},
					}),
				]}
			>
				<Input.Password
					onChange={(e) => setconfirmPassword(e.target.value)}
					prefix={<LockOutlined />}
					placeholder="Confirm Password"
					name="password_confirmation"
					data-id="txtConfirmNewPassword"
				/>
			</Form.Item>
			<div className="sb-modal-footer" style={{ marginBottom: 0 }}>
				<Button className="cancel-button" onClick={onCancel} variant="outlined" key="back" htmlType="button" data-id="btnCancel">
					Cancel
				</Button>
				<Button
					loading={updatePasswordResponse?.isLoading}
					className="submit-button"
					type="secondary"
					variant="solid"
					key="submit"
					htmlType="submit"
					data-id="btnSubmit"
				>
					Submit
				</Button>
			</div>
		</Form>
	);
}

