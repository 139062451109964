// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React from "react";
import { Card } from "antd";
import styled from "styled-components";
import dayjs from "dayjs";
import moment from "moment";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import StyledCalendarCard from "./styles";
import { CalendarIcon, ChevronLeftIcon, LiveIcon } from "components/Icons";
import { useGetDomainGymQuery, useGetGymWorkoutsQuery, useGetScheduledStreamsQuery } from "store/api/api";
import { useNavigate, useParams } from "react-router-dom";
import { daysOfWeekShort, months } from "constants/calendarStuff";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { decodeString } from "utils";

dayjs.extend(utc);
dayjs.extend(timezone);

const Button = styled.button`
	width: 48px;
	height: 48px;
	border-radius: 8px;
	border: 1px solid #e3e5e7;
	color: #787d86;
	background-color: #ffffff;
	align-items: center;
	display: flex;
	justify-content: center;
	cursor: pointer;
	&:hover {
		background-color: #f5f6f7;
	}
`;

const DayButton = styled.button`
	width: 48px;
	height: 48px;
	border-radius: 50%;
	background: ${(props) => (props.selected ? "var(--primary-color)" : "transparent")};
	align-items: center;
	display: flex;
	justify-content: center;
	cursor: pointer;
	border: none;
	color: ${(props) => (props.selected ? "#ffffff" : "#000")};
	&:hover {
		background-color: #f5f6f7;
		color: #000;
	}
`;

const JoinStreamButton = styled.button`
	background: var(--primary-color);
	align-items: center;
	width: 75px;
	display: flex;
	justify-content: center;
	cursor: pointer;
	border: none;
	background: #f2994a;
	color: #ffffff;
	box-shadow: 0px 4px 10px #f2994a;
	border-radius: 4px;
	&:hover {
		opacity: 0.8;
	}
	&:disabled {
		cursor: auto;
		background: #e3e5e7;
		box-shadow: 0 0 0 0;
		color: #787d86;
		&:hover {
			opacity: 1;
		}
	}
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 22px;
	padding: 4px;
`;

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const CalendarCard = (props) => {
	const today = dayjs();
	const navigate = useNavigate();
	const [selectedDate, setSelectedDate] = React.useState(today);
	const handleJoinClick = (workout) => {
		navigate(`../view-live-workout/${workout.id}`);
	};
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse?.data?.id;

	const workoutsResponse = useGetGymWorkoutsQuery({ gym_id: domainGymId }, { skip: !domainGymResponse.data });
	const { data: scheduledStreams } = useGetScheduledStreamsQuery(domainGymId, { skip: !domainGymResponse.data });
	const liveWorkouts =
		workoutsResponse.data?.workouts?.filter(
			(workout) =>
				workout.start_time !== null ||
				moment.tz(workout.start_time, "YYYY-MM-DDTHH:mm:ss", "UTC").isAfter(moment.tz("UTC"))
		) || [];

	const handleSelectDay = (day) => {
		setSelectedDate(day);
	};

	const handleNextWeek = () => {
		setSelectedDate(dayjs(selectedDate).add(1, "week"));
	};

	const handlePrevWeek = () => {
		setSelectedDate(dayjs(selectedDate).subtract(1, "week"));
	};

	return (
		<StyledCalendarCard className="sb-calendar-card">
			<Card className="sb-calendar-card__inner">
				<div className="sb-calendar-card__inner__header-section">
					<div className="sb-calendar-card__inner__header-section__left">
						<h1>{months[selectedDate.month()]}</h1>
						<h2>{selectedDate.year()}</h2>
					</div>
					<div className="sb-calendar-card__inner__header-section__right">
						<Button>
							<ChevronLeftIcon onClick={handlePrevWeek} />
						</Button>
						<Button>
							<ChevronLeftIcon onClick={handleNextWeek} style={{ rotate: "180deg" }} />
						</Button>
					</div>
				</div>
				<div className="sb-calendar-card__inner__week-section">
					<div className="sb-calendar-card__inner__week-section__days">
						<div className="sb-calendar-card__inner__week-section__days__day">
							<h1>{daysOfWeekShort[selectedDate.subtract(3, "day").day()]}</h1>
						</div>
						<div className="sb-calendar-card__inner__week-section__days__day">
							<h1>{daysOfWeekShort[selectedDate.subtract(2, "day").day()]}</h1>
						</div>
						<div className="sb-calendar-card__inner__week-section__days__day">
							<h1>{daysOfWeekShort[selectedDate.subtract(1, "day").day()]}</h1>
						</div>
						<div className="sb-calendar-card__inner__week-section__days__day">
							<h1>{daysOfWeekShort[selectedDate.day()]}</h1>
						</div>
						<div className="sb-calendar-card__inner__week-section__days__day">
							<h1>{daysOfWeekShort[selectedDate.add(1, "day").day()]}</h1>
						</div>
						<div className="sb-calendar-card__inner__week-section__days__day">
							<h1>{daysOfWeekShort[selectedDate.add(2, "day").day()]}</h1>
						</div>
						<div className="sb-calendar-card__inner__week-section__days__day">
							<h1>{daysOfWeekShort[selectedDate.add(3, "day").day()]}</h1>
						</div>
					</div>
					<div className="sb-calendar-card__inner__week-section__days">
						<div className="sb-calendar-card__inner__week-section__days__day">
							<DayButton onClick={() => handleSelectDay(selectedDate.subtract(3, "day"))}>
								{selectedDate.subtract(3, "day").date()}
							</DayButton>
						</div>
						<div className="sb-calendar-card__inner__week-section__days__day">
							<DayButton onClick={() => handleSelectDay(selectedDate.subtract(2, "day"))}>
								{selectedDate.subtract(2, "day").date()}
							</DayButton>
						</div>
						<div className="sb-calendar-card__inner__week-section__days__day">
							<DayButton onClick={() => handleSelectDay(selectedDate.subtract(1, "day"))}>
								{selectedDate.subtract(1, "day").date()}
							</DayButton>
						</div>
						<div className="sb-calendar-card__inner__week-section__days__day">
							<DayButton selected={true}>{selectedDate.date()}</DayButton>
						</div>
						<div className="sb-calendar-card__inner__week-section__days__day">
							<DayButton onClick={() => handleSelectDay(selectedDate.add(1, "day"))}>
								{selectedDate.add(1, "day").date()}
							</DayButton>
						</div>
						<div className="sb-calendar-card__inner__week-section__days__day">
							<DayButton onClick={() => handleSelectDay(selectedDate.add(2, "day"))}>
								{selectedDate.add(2, "day").date()}
							</DayButton>
						</div>
						<div className="sb-calendar-card__inner__week-section__days__day">
							<DayButton onClick={() => handleSelectDay(selectedDate.add(3, "day"))}>
								{selectedDate.add(3, "day").date()}
							</DayButton>
						</div>
					</div>
				</div>
				<div className="sb-calendar-card__inner__divider" />
				<div className="sb-calendar-card__inner__scheduled-header">
					<CalendarIcon />
					<div className="sb-calendar-card__inner__scheduled-header__text-container">
						<p>Scheduled Classes</p>
					</div>
				</div>
				<div className="sb-calendar-card__inner__scheduled-header__subtitle">
					<p>{dayjs(selectedDate).format("ddd MMM D")}</p>
				</div>
				<div className="sb-calendar-card__inner__scheduled-list">
					{scheduledStreams &&
						scheduledStreams
							.filter((stream) => {
								return (
									selectedDate.endOf("day").isAfter(dayjs(stream.start_time)) &&
									selectedDate.startOf("day").isBefore(dayjs(stream.start_time))
								);
							})
							.sort((a, b) => {
								const isAfter = dayjs.tz(a.start_time, a.time_zone).isAfter(dayjs.tz(b.start_time, b.time_zone));
								if (isAfter) return 1;
								else return -1;
							})
							.map((stream) => {
								const match = liveWorkouts.find((workout) => workout.id === stream.workout_id);
								if (!match) return null;
								return renderScheduledWorkoutCard(match, stream);
							})}
				</div>
			</Card>
		</StyledCalendarCard>
	);

	function renderScheduledWorkoutCard(workout, stream) {
		const { name, coach, duration, categories, equipment, music_genre, playback_id } = workout;
		const { start_time, time_zone, currently_streaming } = stream;
		const durationInMinutes = Math.ceil(duration / 60);

		return (
			<div key={workout.id} className="sb-calendar-card__inner__scheduled-list__item">
				<div className="sb-calendar-card__inner__scheduled-list__item__left">
					<h1>{decodeString(name)}</h1>
					<div className="sb-calendar-card__inner__scheduled-list__item__left__subtitle-container">
						<p>
							{dayjs.tz(start_time, time_zone).format("MMMM D, YYYY h:mm A z")}{" "}
							{dayjs.tz.guess() !== time_zone
								? "(Local : " + dayjs.tz(start_time, time_zone).tz(dayjs.tz.guess()).format("h:mm A") + ") "
								: null}
							| {durationInMinutes}
							mins
						</p>
						<h6>Scheduled</h6>
					</div>
					<div className="sb-calendar-card__inner__scheduled-list__item__left__tags-container">
						<div className="sb-calendar-card__inner__scheduled-list__item__left__tags-container__tag">{coach}</div>
						{categories?.map((category, i) => (
							<div
								key={`${category.name}-${i}`}
								className="sb-calendar-card__inner__scheduled-list__item__left__tags-container__tag"
							>
								{category.name}
							</div>
						))}
						{equipment
							?.split(",")
							?.filter(Boolean)
							?.map((equipment, i) => (
								<div
									key={`${equipment}-${i}`}
									className="sb-calendar-card__inner__scheduled-list__item__left__tags-container__tag test"
								>
									{equipment}
								</div>
							))}
						<div className="sb-calendar-card__inner__scheduled-list__item__left__tags-container__tag">
							{music_genre}
						</div>
					</div>
				</div>
				<div className="sb-calendar-card__inner__scheduled-list__item__right">
					{(!currently_streaming || !playback_id) && dayjs.tz(start_time, time_zone).local().isAfter(dayjs()) && (
						<p>in {dayjs.tz(start_time, time_zone).toNow(true)}</p>
					)}
					{!!currently_streaming && (
						<div className="sb-calendar-card__inner__scheduled-list__item__right__in-progress">
							<LiveIcon style={{ height: "24px" }} />
							<p> In Progress</p>
						</div>
					)}
					{!currently_streaming && dayjs.tz(start_time, time_zone).local().isBefore(dayjs()) && (
						<div className="sb-calendar-card__inner__scheduled-list__item__right__in-progress">
							<LiveIcon style={{ height: "24px" }} />
							<p> {`was scheduled to start ${dayjs.tz(start_time, time_zone).local().toNow(true)} ago`}</p>
						</div>
					)}
					<JoinStreamButton
						onClick={() => handleJoinClick(workout)}
						active
						disabled={!currently_streaming || !playback_id}
					>
						JOIN
					</JoinStreamButton>
				</div>
			</div>
		);
	}
};

export default CalendarCard;

