import React, { useEffect, useRef } from "react";
import ValidationError from "../ValidationError";
import { Select } from "antd";

import "./index.scss";
import usePrimaryColor from "helpers/hooks/usePrimaryColor";
import alpha from "helpers/alpha";

function SelectField({ meta, options, placeholder, input, ...rest }) {
	const primaryColor = usePrimaryColor();
	const ref = useRef(null);
	useEffect(() => {
		// Antd does not display the placeholder when showSearch is true; this is a workaround
		if (rest.showSearch && ref.current) {
			const selectInput = ref.current?.querySelector(`input[type="search"]`);
			if (selectInput) {
				selectInput.placeholder = !input.value ? placeholder : "";
			}
		}
	}, [input.value, placeholder, rest.showSearch]);

	return (
		<div
			ref={ref}
			style={{ "--primary-color": primaryColor, "--background-color": alpha(primaryColor, 0.3) }}
			className="select-field-wrapper"
		>
			<Select placeholder={placeholder} size="large" {...input} {...rest}>
				{options.map((option, i) => {
					return (
						<Select.Option key={i} value={option.value}>
							{option.label}
						</Select.Option>
					);
				})}
			</Select>
			<ValidationError meta={meta} />
		</div>
	);
}

export default SelectField;
