// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate, useParams } from "react-router";

// -----------------------------------------------------------------------------
// Components, helpers, styles and selectors
// -----------------------------------------------------------------------------
import StyledWorkoutsList from "./styles";
import { decodeString } from "utils";
import { WorkoutCard } from "components/Workouts/WorkoutCard";
import { selectMembersWorkoutsSearchTerm, selectMembersWorkoutsSelectedCategories } from "store/selectors";
import { useGetDomainGymQuery, useGetGymWorkoutsQuery } from "store/api/api";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const WorkoutsList = ({ dateRange }) => {
	const navigate = useNavigate();
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse?.data?.id;

	const workoutsResponse = useGetGymWorkoutsQuery(
		{
			gym_id: domainGymId,
			start_date: dateRange.startDate.format("YYYY-MM-DD"),
			end_date: dateRange.endDate.format("YYYY-MM-DD"),
		},
		{ skip: !domainGymResponse.data }
	);
	const workouts = workoutsResponse.data?.workouts || [];

	const categoriesFilters = useSelector(selectMembersWorkoutsSelectedCategories);
	const searchTerm = useSelector(selectMembersWorkoutsSearchTerm);

	const handleFileSelected = (workoutId) => {
		navigate(`../view-workout/${workoutId}`);
	};

	if (!workouts || !workouts.length) {
		return <div>No Workouts</div>;
	}

	return (
		<StyledWorkoutsList className="sb-workouts">
			{workouts
				.filter(
					(workout) =>
						workout.draft === 0 &&
						(categoriesFilters.includes(0) || workout.categories.some((c) => categoriesFilters.includes(c.category_id)))
				)
				.filter((workout) => workout.duration !== null && workout.duration !== 0)
				.filter(
					(workout) =>
						workout.start_time === null ||
						moment
							.tz(workout.start_time, "YYYY-MM-DDTHH:mm:ss", workout?.time_zone || moment.tz.guess())
							.isBefore(moment())
				)
				.filter((workout) => workout.playback_id !== "null" && workout.playback_id)
				.filter((workout) => (searchTerm !== "" ? decodeString(workout.name).toLowerCase().includes(searchTerm) : true))
				.sort((a, b) => new Date(b.created_date) - new Date(a.created_date))
				.map((workout) => (
					<div key={workout.id} onClick={() => handleFileSelected(workout.id)}>
						<WorkoutCard
							title={decodeString(workout.name)}
							categories={workout.categories}
							headerImage={workout.image_url}
						/>
					</div>
				))}
		</StyledWorkoutsList>
	);
};

export default WorkoutsList;
