// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import CreateAccountForm from "./CreateAccountForm";
import { userAtom } from "../../../globalAtoms";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const CreateAccount = () => {
	const user = useRecoilValue(userAtom);
	if (user && !user?.gym?.subdomain) {
		return <Navigate to="/creator-creation/your-information" />;
	}

	return (
		<div className="sb-creator-creation">
			<div className="sb-creator-creation__inner">
				<div className="sb-creator-creation__inner__title">
					<h1 className="sb-creator-creation__inner__title__title">Let's Get Started</h1>
					<p className="sb-creator-creation__inner__title__subtitle">
						Please enter your email address and create a password so we can save your progress.
					</p>
				</div>
				<div className="sb-creator-creation__inner__header">
					<p className="sb-creator-creation__inner__header__text">ACCOUNT INFORMATION</p>
				</div>

				<CreateAccountForm />
			</div>
		</div>
	);
};

export default CreateAccount;
