// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect } from "react";
import moment from "moment";
import { Button, Card, Flex, Space } from "antd";
import { useParams } from "react-router";
import { CaretDownFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledAnalytics from "./styles";
import AnalyticsTable from "./AnalyticsTable";

// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import {
	useGetCategoriesQuery,
	useGetDomainGymQuery,
	useGetGymWorkoutsQuery,
	useGetStatsForGymQuery,
} from "store/api/api";
import { updateMembersWorkoutsSelectedCategories } from "store/slices";
import { selectMembersWorkoutsSelectedCategories } from "store/selectors";
import Dropdown from "components/Dropdown";
import "./styles.scss";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
function Analytics() {
	const dispatch = useDispatch();
	let { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse?.data?.id;
	const [selectedCategories] = React.useState([]);
	const [timeFrame, setTimeFrame] = React.useState("Last 30 Days");
	const [dateFilter, setDateFilter] = React.useState({
		startDate: "",
		endDate: "",
	});
	const categoriesFilters = useSelector(selectMembersWorkoutsSelectedCategories);
	const categoriesResponse = useGetCategoriesQuery(domainGymId, { skip: !domainGymResponse.data });
	const categories = categoriesResponse.data?.categories || [];
	const [homeCardData, setHomeCardData] = React.useState({
		videoViews: 0,
		activeUsers: 0,
		avgViewsPerUser: 0,
	});

	const { data: statsData, isFetching } = useGetStatsForGymQuery(
		{
			gym_id: domainGymId,
			start_date: dateFilter.startDate,
			end_date: dateFilter.endDate,
			types: ["workout_start", "workout_user_start"],
		},
		{
			skip: !domainGymResponse.data,
		}
	);

	const workoutsResponse = useGetGymWorkoutsQuery({ gym_id: domainGymId }, { skip: !domainGymResponse.data });
	const [workouts, setWorkouts] = React.useState([]);

	const handleTimeClick = (timeframe) => {
		setTimeFrame(timeframe.key);
	};

	const handleCategoryClick = (category) => {
		const category_id = parseInt(category.key);

		if (!selectedCategories.includes(category_id)) {
			if (category_id === 0) {
				dispatch(updateMembersWorkoutsSelectedCategories([0]));
			} else {
				if (selectedCategories.includes(0)) {
					dispatch(updateMembersWorkoutsSelectedCategories([category_id]));
				} else {
					dispatch(updateMembersWorkoutsSelectedCategories([...selectedCategories, category_id]));
				}
			}
		} else if (category_id !== 0) {
			const newCategories = [...selectedCategories];

			newCategories.splice(
				selectedCategories.findIndex((c) => c === category_id),
				1
			);

			dispatch(updateMembersWorkoutsSelectedCategories(newCategories.length > 0 ? newCategories : [0]));
		}
	};

	const categoryItems = categories.map((category) => {
		return {
			label: category.name,
			key: category.id,
			danger: categoriesFilters.includes(category.id) ? true : false,
			className: "analytics-menu-item",
			title: category.name,
		};
	});

	useEffect(() => {
		return () => {
			dispatch(updateMembersWorkoutsSelectedCategories([0]));
		};
	}, [dispatch]);

	const categoriesMenu = {
		items: [
			{
				label: "All Categories",
				key: 0,
				danger: categoriesFilters.includes(0) ? true : false,
			},
			...categoryItems,
		],
		onClick: handleCategoryClick,
	};

	const timeframeMenu = {
		items: [
			{
				label: "All Time",
				key: "All Time",
				danger: timeFrame === "All Time" ? true : false,
				className: "analytics-menu-item",
			},
			{
				label: "Last Week",
				key: "Last Week",
				danger: timeFrame === "Last Week" ? true : false,
				className: "analytics-menu-item",
			},
			{
				label: "Last 30 Days",
				key: "Last 30 Days",
				danger: timeFrame === "Last 30 Days" ? true : false,
				className: "analytics-menu-item",
			},
			{
				label: "Last 3 Months",
				key: "Last 3 Months",
				danger: timeFrame === "Last 3 Months" ? true : false,
				className: "analytics-menu-item",
			},
			{
				label: "Last 6 Months",
				key: "Last 6 Months",
				danger: timeFrame === "Last 6 Months" ? true : false,
				className: "analytics-menu-item",
			},
			{
				label: "Last Year",
				key: "Last Year",
				danger: timeFrame === "Last Year" ? true : false,
				className: "analytics-menu-item",
			},
		],
		onClick: handleTimeClick,
	};

	const categoryName = (category_id) => {
		const category = categories.find((c) => c.id === category_id);
		return <div className="sb-category_name">{category ? category.name : "All Categories"}</div>;
	};

	React.useEffect(() => {
		if (statsData) {
			// Filter Workouts Data By Category
			const filteredWorkouts =
				workouts?.filter(
					(workout) =>
						categoriesFilters.includes(0) || workout.categories.some((c) => categoriesFilters.includes(c.category_id))
				) ?? [];

			// Filter Stats Data By Workouts
			const statsFiltered = statsData.filter((stat) =>
				filteredWorkouts.map((workout) => workout.key).includes(stat.target)
			);

			// Update Home Card Data
			const stats = statsFiltered.filter((stat) => stat.type === "workout_start" || stat.type === "workout_user_start");

			const totalViews = stats.reduce((acc, stat) => {
				return +stat.total_views + acc;
			}, 0);

			const uniqueUserIds = new Set(
				stats.flatMap((stat) => (stat?.user_ids ? stat.user_ids.split(",")?.filter((id) => id) : []))
			);

			// Count the number of unique user IDs
			const uniqueUserCount = uniqueUserIds.size;

			const toSet = {
				videoViews: totalViews,
				activeUsers: uniqueUserCount,
				avgViewsPerUser: ((totalViews || 0) / (uniqueUserCount || 1)).toFixed(2),
			};

			setHomeCardData(toSet);
		}
	}, [statsData, timeFrame, workouts, categoriesFilters]);

	React.useEffect(() => {
		const today = moment().format("YYYY-MM-DD");
		if (timeFrame === "Last 30 Days") {
			setDateFilter({
				startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
				endDate: today,
			});
		} else if (timeFrame === "Last Week") {
			setDateFilter({
				startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
				endDate: today,
			});
		} else if (timeFrame === "Last 3 Months") {
			setDateFilter({
				startDate: moment().subtract(3, "months").format("YYYY-MM-DD"),
				endDate: today,
			});
		} else if (timeFrame === "Last 6 Months") {
			setDateFilter({
				startDate: moment().subtract(6, "months").format("YYYY-MM-DD"),
				endDate: today,
			});
		} else if (timeFrame === "Last Year") {
			setDateFilter({
				startDate: moment().subtract(1, "year").format("YYYY-MM-DD"),
				endDate: today,
			});
		} else {
			setDateFilter({
				startDate: "",
				endDate: "",
			});
		}
	}, [timeFrame]);

	React.useEffect(() => {
		if (workoutsResponse.data) {
			setWorkouts(workoutsResponse.data.workouts);
		}
	}, [workoutsResponse]);

	return (
		<StyledAnalytics className="sb-analytics">
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					paddingBottom: "16px",
					flexWrap: "wrap",
				}}
			>
				<h1>Analytics</h1>
				<div style={{ display: "flex", justifyContent: "space-between", gap: "16px", flexWrap: "wrap" }}>
					<Space.Compact size="large">
						<Dropdown
							trigger={["click"]}
							overlayClassName="sb-analytics_dropdown"
							style={{ flex: 1 }}
							menu={categoriesMenu}
						>
							<Button
								style={{
									borderColor: " #E3E5E7",
									textTransform: "none",
									color: "#4F5661",
									fontWeight: "700",
									fontSize: "14px",
									lineHeight: "24px",
									fontFamily: "DM Sans",
									width: "100%",
								}}
							>
								{categoryName(categoriesFilters[0])}

								<CaretDownFilled
									style={{
										color: "#4F5661",

										fontSize: "12px",
										lineHeight: "0px",
									}}
								/>
							</Button>
						</Dropdown>
					</Space.Compact>
					<Space.Compact style={{ flex: 1 }} size="large">
						<Dropdown trigger={["click"]} overlayClassName="sb-analytics_dropdown" menu={timeframeMenu}>
							<Button
								style={{
									borderColor: " #E3E5E7",
									textTransform: "none",
									color: "#4F5661",
									fontWeight: "700",
									fontSize: "14px",
									lineHeight: "24px",
									fontFamily: "DM Sans",
									width: "100%",
								}}
							>
								Time Frame: {timeFrame}
								<CaretDownFilled
									style={{
										color: "#4F5661",

										fontSize: "12px",
										lineHeight: "0px",
									}}
								/>
							</Button>
						</Dropdown>
					</Space.Compact>
				</div>
			</div>
			<Flex justify="left" wrap>
				<Card hoverable className="sb-creator-home__section__card">
					<p className="sb-creator-home__section__card__title">VIDEO VIEWS</p>
					<p className="sb-creator-home__section__card__value">{homeCardData["videoViews"]}</p>
				</Card>
				<Card hoverable className="sb-creator-home__section__card">
					<p className="sb-creator-home__section__card__title">TOTAL ACTIVE USERS</p>
					<p className="sb-creator-home__section__card__value">{homeCardData["activeUsers"]}</p>
				</Card>
				<Card hoverable className="sb-creator-home__section__card">
					<p className="sb-creator-home__section__card__title">AVG. VIEWS PER USER</p>
					<p className="sb-creator-home__section__card__value">{homeCardData["avgViewsPerUser"]}</p>
				</Card>
			</Flex>
			<div style={{ display: "flex", justifyContent: "left", paddingTop: "16px" }}>
				<h1>Most Popular Workouts</h1>
			</div>
			<AnalyticsTable loading={isFetching} statsData={statsData} workoutsResponse={workoutsResponse} />
		</StyledAnalytics>
	);
}

export default Analytics;
