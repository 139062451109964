// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { CategoriesCard } from "components/Categories/CategoriesCard";
import { CreateWorkoutModal } from "components/Modals/CreateWorkoutModal";
import { EditWorkoutModal } from "components/Modals/EditWorkoutModal";
import { ScheduleLiveModal } from "components/Modals/ScheduleLiveModal";
import WorkoutsTable from "components/Workouts/WorkoutsTable";
import { updateSelectedMuxVideoUrl, updateSelectedWorkout } from "store/slices";
import classes from "./styles.module.scss";

// -----------------------------------------------------------------------------
// Styles and utils
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const Workouts = () => {
	const dispatch = useDispatch();
	const [showCreateWorkoutModal, setShowCreateWorkoutModal] = useState(false);
	const [showEditWorkoutModal, setShowEditWorkoutModal] = useState(false);
	const [isEditingLive, setIsEditingLive] = useState(false);
	const [selectedLiveStream, setSelectedLiveStream] = useState(null);
	const [showLiveScheduleModal, setShowLiveScheduleModal] = useState(false);

	const openWorkoutCreationModal = useCallback(() => {
		dispatch(updateSelectedWorkout({ workout: null }));
		dispatch(updateSelectedMuxVideoUrl({ url: null }));
		setShowCreateWorkoutModal(true);
	}, [dispatch]);

	const onCreateSetVisible = useCallback(
		(status) => {
			setShowCreateWorkoutModal(status);

			if (!status) {
				dispatch(updateSelectedWorkout({ workout: null }));
				dispatch(updateSelectedMuxVideoUrl({ url: null }));
			}
		},
		[dispatch]
	);

	const onEditSetVisible = (bool) => {
		setShowEditWorkoutModal(bool);

		if (!bool) {
			dispatch(updateSelectedWorkout({ workout: null }));
			dispatch(updateSelectedMuxVideoUrl({ url: null }));
		}
	};

	return (
		<div className="sb-creator-workouts">
			<div className="sb-creator-workouts__categories">
				<CategoriesCard />
			</div>
			<div className="sb-creator-workouts__body">
				<div style={{ width: "100%" }} className={classes["table-head"]}>
					<Button
						data-id="workout-library-new-workout-button"
						icon={<PlusOutlined />}
						type="primary"
						onClick={setShowLiveScheduleModal}
						style={{ flex: 1 }}
					>
						Schedule Live
					</Button>
					<Button
						data-id="workout-library-new-workout-button"
						icon={<PlusOutlined />}
						type="primary"
						onClick={openWorkoutCreationModal}
						style={{ flex: 1 }}
					>
						New Workout
					</Button>
				</div>
				<WorkoutsTable
					setShowEditWorkoutModal={setShowEditWorkoutModal}
					setIsEditingLive={setIsEditingLive}
					setSelectedLiveStream={setSelectedLiveStream}
				/>
			</div>
			<ScheduleLiveModal open={showLiveScheduleModal} setOpen={setShowLiveScheduleModal} />
			<CreateWorkoutModal open={showCreateWorkoutModal} setOpen={onCreateSetVisible} />
			<EditWorkoutModal
				open={showEditWorkoutModal}
				setOpen={onEditSetVisible}
				isEditingLive={isEditingLive}
				setIsEditingLive={setIsEditingLive}
				liveStream={selectedLiveStream}
			/>
		</div>
	);
};

export default Workouts;
