// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams, Navigate } from "react-router";
// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { EarningsCard } from "components/Common/Cards/EarningsCard";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledEarningsPage from "./styles";
import {
	useStripeSearchSubscriptionsMutation,
	useLazyStripeGetCustomerSubscriptionsQuery,
	useStripeFetchTransfersMutation,
	useGetDomainGymQuery,
} from "store/api/api";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../../globalAtoms";
import { LoadingMask } from "components/Common/LoadingMask";
import { EarningsTable } from "components/Payments/EarningsTable";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const EarningsPage = () => {
	const { gym } = useParams();
	const navigate = useNavigate();
	const user = useRecoilValue(userAtom);
	const domainGymResponse = useGetDomainGymQuery(gym);
	const isUsedSBPayments = useMemo(() => !!(domainGymResponse?.data?.is_used_sb_payments ?? 1), [domainGymResponse]);

	const [transfers, setTransfers] = useState([]);
	const [pendingBalance, setPendingBalance] = useState(0);
	const [totalEarnings, setTotalEarnings] = useState(0);

	const [searchSubscriptionsTrigger] = useStripeSearchSubscriptionsMutation();
	const [getSubscriptionsTrigger, getCustomerSubscriptionsResponse] = useLazyStripeGetCustomerSubscriptionsQuery();
	const [fetching, setIsFetching] = useState(true);
	const [connectedAccountId, setConnectedAccountId] = useState(null);

	const subscribed = getCustomerSubscriptionsResponse.data?.subscriptions?.length > 0;
	const [fetchTransfersTrigger] = useStripeFetchTransfersMutation();

	useEffect(() => {
		if (user?.gym?.stripe_info?.stripe_connected_account_id) {
			setConnectedAccountId(user.gym.stripe_info.stripe_connected_account_id);
		}
	}, [user]);

	useEffect(() => {
		setIsFetching(true);
		(async () => {
			await getSubscriptionsTrigger(
				{
					customer_id: user.customer_id,
				},
				false
			);
			setIsFetching(false);
		})();
	}, [getSubscriptionsTrigger, user.customer_id]);

	useEffect(() => {
		if (!isUsedSBPayments) {
			navigate("../");
		}
	}, [isUsedSBPayments, navigate]);

	//TODO: Now that we have fetched subscriber data, we still need to format to pull out earnings, and dates
	const fetchSubscriberData = async () => {
		try {
			await searchSubscriptionsTrigger({
				account_id: connectedAccountId,
			});
		} catch (error) {
			console.debug("ERROR FETCHING SUBSCRIBER DATA: ", error);
		}
	};

	const fetchDetailedEarnings = async () => {
		try {
			const transfers = await fetchTransfersTrigger({
				account_id: connectedAccountId,
				details: true,
			}).unwrap();

			setTransfers(transfers?.details || []);
			setPendingBalance(transfers?.pending_balance);
			setTotalEarnings(transfers?.payouts_sum);
		} catch (error) {
			console.debug("ERROR FETCHING SUBSCRIBER DATA: ", error);
		}
	};

	useEffect(() => {
		if (connectedAccountId) {
			fetchSubscriberData();
			fetchDetailedEarnings();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connectedAccountId]);

	return (
		<StyledEarningsPage className="sb-earnings">
			{getCustomerSubscriptionsResponse.isFetching || domainGymResponse?.isLoading || fetching ? (
				<LoadingMask />
			) : subscribed ? (
				<>
					<div className="sb-earnings__top-section">
						<EarningsCard pendingBalance={pendingBalance} totalEarnings={totalEarnings} />
					</div>

					<div style={{ padding: "8px", paddingTop: "24px" }}>
						<EarningsTable transfers={transfers} />
					</div>
				</>
			) : (
				<Navigate to={`/${gym}/dashboard/select-plan`} />
			)}
		</StyledEarningsPage>
	);
};

export default EarningsPage;
