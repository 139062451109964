import styled from "styled-components";

const StyledGenreSelector = styled.div`
	position: relative;
	width: 100%;

	.sb-genre-selector {
		&__genres {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
			flex-wrap: wrap;

			&__genre {
				width: 174px;
				height: 174px;
				perspective: 1000px;

				&__inner {
					position: relative;
					width: 100%;
					height: 100%;
					text-align: center;
					transition: transform 0.6s;
					transform-style: preserve-3d;
					border-radius: 16px;
					box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

					&__front,
					&__back {
						position: absolute;
						width: 100%;
						height: 100%;
						backface-visibility: hidden;
						cursor: pointer;
					}

					&__back {
						transform: rotateY(180deg);
						border-radius: 12px;
						padding: 16px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: ${({ theme }) => theme.colors.jungleGreen};
					}

					img {
						border-radius: 12px;
					}

					&__check {
						opacity: 0;
						position: absolute;
						display: flex;
						top: 8px;
						right: 8px;
						background-color: ${({ theme }) => theme.colors.coral};
						align-items: center;
						justify-content: center;
						width: 16px;
						height: 16px;
						border-radius: 50%;
						color: ${({ theme }) => theme.colors.white};
					}
				}

				&--selected {
					border: 4px solid ${({ theme }) => theme.colors.coral};
					border-radius: 16px;

					.sb-genre-selector__genres__genre__inner__check {
						transition: opacity 1s;
						opacity: 1;
					}
				}

				&:hover {
					.sb-genre-selector__genres__genre__inner {
						transform: rotateY(180deg);
					}

					.sb-genre-selector__genres__genre__inner__check {
						transition: opacity 0.1s;
						opacity: 0;
					}
				}
			}
		}
	}
`;

export default StyledGenreSelector;

