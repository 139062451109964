// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Heading4, Paragraph4 } from "themes/default/_typography";
import ChevronLeftIconStories from "components/Icons/ChevronLeftIcon/ChevronLeftIcon";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import { bem } from "helpers/Bem";
import StyledInformationCard from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const GeneralInformationCard = ({ onClick, icon, header, text, customColor, transparent, url }) => {
	return (
		<StyledInformationCard>
			<Link to={url}>
				<Card hoverable onClick={onClick} className={bem`sb-information-card--${{ transparent }}`}>
					<div className={`sb-information-card__icon ${customColor ? "card-custom-color" : ""}`}>{icon}</div>
					<div className="sb-information-card__body">
						{header && (
							<Heading4
								className={`sb-information-card__body__header ${customColor ? "card-custom-color" : ""}`}
								style={{
									textAlign: "left",
									marginBottom: "0",
									margin: 0,
									justifyContent: "center",
								}}
							>
								{header}
							</Heading4>
						)}
						{text && <Paragraph4 className="sb-information-card__body__text">{text}</Paragraph4>}
					</div>
					<div className="sb-information-card__arrow">
						<ChevronLeftIconStories />
					</div>
				</Card>
			</Link>
		</StyledInformationCard>
	);
};

export default GeneralInformationCard;

