import React from "react";
import { Button, Flex, Typography } from "antd";
import ErrorPageBackground from "../../images/error-page-background.svg";
import { Logo } from "components/Icons";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";

const ErrorPage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search);
	const message = searchParams.get("message") || "An unexpected error has occurred.";
	const subdomain = searchParams.get("subdomain");
	console.log("ErrorPage -> message", message);
	return (
		<Flex className="error-page">
			<Flex align="center" vertical gap={24} flex={1} justify="center" className="body">
				<Logo />
				<Typography.Text className="error-message">{message}</Typography.Text>
				<img src={ErrorPageBackground} alt="Background" className="background-image" />
				<Button className="button" type="primary" onClick={() => navigate(`/login/${subdomain}`)}>
					Login
				</Button>
			</Flex>
			<Flex flex={1} className="login-image">
				p
			</Flex>
		</Flex>
	);
};

export default ErrorPage;

