import styled from "styled-components";
import ContactSalesModal from "./ContactSalesModal";

const StyledContactSalesModal = styled(ContactSalesModal)`
	.sb-contact-sales-modal {
		&__subtitle {
			margin-bottom: 48px;

			p {
				text-align: center;
			}
		}

		&__form {
			&__inline {
				display: flex;
				gap: 16px;
				@media (max-width: 575px) {
					flex-wrap: wrap;
				}
			}

			.sb-button {
				margin-top: 24px;
				max-width: 100%;
			}
		}
	}

	.ant-modal-content {
		padding: 24px;

		.ant-modal-header {
			padding: 0;
			border: none;

			.ant-modal-title {
				p {
					text-align: center;
				}
			}
		}

		.ant-modal-body {
			padding: 16px 0 0 0;
		}

		.ant-modal-footer {
			padding: 0;
		}
	}
`;

export default StyledContactSalesModal;

