// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { Input, Form } from "antd";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Label2 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Styles
// -------------------------------------------------------------------------------
import StyledFormInput from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const FormInput = ({
	name,
	label,
	rules,
	autoComplete,
	placeholder = "",
	maxLength = null,
	dark,
	shouldUpdate,
	validateStatus,
	hasFeedback,
	help,
	...rest
}) => {
	const noSpacesRules = {
		validator: (_, value) => {
			if (!value) {
				// So it doesn't double validate
				return Promise.resolve();
			}
			return value.trim() ? Promise.resolve() : Promise.reject(new Error("This field is required."));
		},
	};
	const isFieldRequired = rules?.find(({ required }) => required);
	const ourRules = isFieldRequired ? [...rules, noSpacesRules] : rules;
	return (
		<StyledFormInput className="sb-form-input">
			<Form.Item
				name={name}
				label={<Label2 style={{ color: dark ? "white" : null }}>{label}</Label2>}
				rules={ourRules}
				required={false}
				shouldUpdate={shouldUpdate}
				help={help}
				hasFeedback={hasFeedback}
				validateStatus={validateStatus}
			>
				<Input
					className={dark ? "input-dark" : null}
					placeholder={placeholder || label}
					maxLength={maxLength}
					autoComplete={autoComplete}
					{...rest}
				/>
			</Form.Item>
		</StyledFormInput>
	);
};

export default FormInput;

