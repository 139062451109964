import styled from "styled-components";

const StyledInformationCard = styled.div`
	width: 100%;

	.sb-information-card {
		max-width: 800px;

		.ant-card-body {
			background: ${({ theme }) => theme.colors.white};
			box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
			border-radius: 12px;
			padding: 24px 30px 24px 24px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			cursor: pointer;
			padding: 10px;
			@media (max-width: ${({ theme }) => theme.responsive.mobile}) {
				gap: 8px;
			}
		}

		&--transparent {
			background: rgba(255, 255, 255, 0.2);
			border-radius: 12px;
			border: none;

			.ant-card-body {
				background: rgba(255, 255, 255, 0.2);
			}

			&__arrow {
				color: ${({ theme }) => theme.colors.white};
			}
		}

		&__icon {
			width: 56px;
			height: 56px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 16px;

			img {
				max-width: 56px;
				max-height: 56px;
			}

			&.card-custom-color {
				color: ${({ theme }) => theme.colors.coral};
			}
		}

		&__body {
			&__header {
				margin: 4px 0;

				&.card-custom-color {
					color: ${({ theme }) => theme.colors.coral};
				}
			}

			&__text {
				opacity: 0.5;
				margin: 4px 0;
			}
		}

		&__arrow {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: auto;

			svg {
				transform: rotate(180deg);
			}
		}

		@media (max-width: ${({ theme }) => theme.responsive.mobile}) {
			flex-direction: column;
			padding: 16px;

			&__icon {
				margin: 0;
			}

			&__body {
				&__header {
					text-align: center;
				}

				&__text {
					overflow: hidden;
					width: 40vw;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}
		}
	}
`;

export default StyledInformationCard;

