// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import Table from "antd/lib/table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import VideoPreview from "components/UI/Video/VideoPreview";
import VideoPlayerModal from "components/UI/VideoPlayer/VideoPlayerModal";
import { LoadingMask } from "components/Common/LoadingMask";
// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectMembersWorkoutsSelectedCategories } from "store/selectors";

// -----------------------------------------------------------------------------
// Style, utils and assets
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const AnalyticsTable = ({ statsData, workoutsResponse, loading }) => {
	const [workouts, setWorkouts] = useState([]);

	const [isShowingVideoPlayer, updateIsShowingVideoPlayer] = useState(false);
	const [fileSelected, updateFileSelected] = useState(null);
	const categoriesFilters = useSelector(selectMembersWorkoutsSelectedCategories);

	useEffect(() => {
		if (workoutsResponse.data) {
			setWorkouts(workoutsResponse.data.workouts);
		}
	}, [workoutsResponse]);

	useEffect(() => {
		if (statsData && workoutsResponse.data) {
			const updatedWorkouts = workoutsResponse.data.workouts?.map((workout) => {
				// Filter the stats to find matching 'target' and 'type' == 'workout_start'/'type' == 'workout_user_start'
				const workoutStat = statsData.find(
					(stat) => stat.target === workout.key && (stat.type === "workout_start" || stat.type === "workout_user_start")
				);

				return {
					...workout,
					total_workout_start_stats: workoutStat ? +workoutStat?.total_views ?? 0 : 0, // Count of workout_start events
					total_unique_users: workoutStat ? workoutStat?.active_users ?? 0 : 0, // Count of unique users who started the workout
				};
			});
			setWorkouts(updatedWorkouts);
		}
	}, [statsData, workoutsResponse]);

	const columns = [
		{
			title: "Rank",
			dataIndex: "rank",
			key: "rank",
			render: (_, record, index) => {
				return (
					<div>
						<span>{index + 1}</span>
					</div>
				);
			},
		},
		{
			title: "Workout",
			dataIndex: "workout",
			key: "workout",
			render: (_, record) => {
				return (
					<VideoPreview
						updateIsShowingVideoPlayer={updateIsShowingVideoPlayer}
						updateFileSelected={updateFileSelected}
						record={record}
					/>
				);
			},
		},
		{
			title: "Total Views",
			dataIndex: "views",
			key: "views",
			render: (_, record) => {
				return <p>{record.total_workout_start_stats}</p>;
			},
		},
		{
			title: "Total Users",
			dataIndex: "users",
			key: "users",
			render: (_, record) => {
				return <span>{record.total_unique_users}</span>;
			},
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			render: (_, record) => {
				return record.start_time === null || record.is_scheduled ? <span>Workout</span> : <span>Live Workout</span>;
			},
		},
	];

	const dataSource = workouts
		.filter(
			(workout) =>
				categoriesFilters.includes(0) || workout.categories.some((c) => categoriesFilters.includes(c.category_id))
		)
		.sort((a, b) => b.total_workout_start_stats - a.total_workout_start_stats);
	return (
		<div className="sb-workouts-table">
			{loading && <LoadingMask />}
			{dataSource?.length > 0 && (
				<Table
					scroll={{ x: dataSource?.length ? "max-content" : undefined }}
					columns={columns}
					dataSource={dataSource}
					rowKey="id"
				/>
			)}
			<VideoPlayerModal
				file={fileSelected}
				isShowing={isShowingVideoPlayer}
				updateIsShowingVideoPlayer={updateIsShowingVideoPlayer}
			/>
		</div>
	);
};

export default AnalyticsTable;

