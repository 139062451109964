import React, { useEffect } from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import { Flex, Alert } from "antd";
import { Button } from "components/Common/Button";
import { LoadingMask } from "components/Common/LoadingMask";
import { useConfirmEmailChangeLinkMutation } from "store/api/api";
import { userAtom } from "globalAtoms";
import { getUser } from "useInit";
import { useSetRecoilState } from "recoil";

const ConfirmEmailChange = () => {
	const { userId, token, gym } = useParams();
	const setUser = useSetRecoilState(userAtom);
	const [confirmEmailChangeTrigger, confirmEmailChange] = useConfirmEmailChangeLinkMutation();

	useEffect(() => {
		const payload = {
			user_id: userId,
			token,
		};
		confirmEmailChangeTrigger(payload);
	}, [userId, token, confirmEmailChangeTrigger]);

	useEffect(() => {
		if (confirmEmailChange.isSuccess) {
			getUser(setUser);
		}
	}, [confirmEmailChange, setUser]);

	if (confirmEmailChange.isLoading) {
		return <LoadingMask />;
	}

	const url = new URL(`/error`, window.location.origin);
	url.searchParams.append(
		"message",
		confirmEmailChange.error?.data?.message || "There was an error changing your email. Please try again."
	);
	url.searchParams.append("subdomain", gym);
	return (
		<Flex vertical gap={16} justify="center" align="center" style={{ padding: "24px" }}>
			<Alert
				style={{ width: "100%" }}
				message={confirmEmailChange.isSuccess ? "Email changed successfully!" : "Error changing email"}
				description={
					confirmEmailChange.isSuccess
						? "Your email has been successfully changed."
						: confirmEmailChange.error?.data?.message || "There was an error changing your email. Please try again."
				}
				type={confirmEmailChange.isSuccess ? "success" : "error"}
				showIcon
			/>
			{confirmEmailChange.error ? <Navigate to={url.pathname + url.search} /> : null}
			<Link to={`/${gym}/dashboard`}>
				<Button className="sb-release-schedule-form__form__button primary-filled" type="primary">
					Go to Dashboard
				</Button>
			</Link>
		</Flex>
	);
};

export default ConfirmEmailChange;

