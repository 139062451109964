import alpha from "helpers/alpha";
import styled from "styled-components";

const StyledReleaseScheduleForm = styled.div`
	padding: 0;
	.sb-release-form-input-group {
		display: flex;
		gap: 16px;
		width: 100%;
	}

	.sb-release-schedule-form {
		&__upload {
			margin: 32px 0;
			&__input {
				margin-bottom: 8px;
			}
		}
		&__form {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 8px;
			&__button {
				margin: 8px 0;
			}
		}
		&__row {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			gap: 8px;
			&__button {
				margin: 8px 0;
			}
		}
		&__notice {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			padding: 8px;
			gap: 8px;
			background: ${(props) => alpha(props.primaryColor, 0.12)};
			border-radius: 8px;
			margin: 16px 0;
		}
		&__actions {
			width: 100%;
			display: flex;
			flex-grow: 1;
			align-items: start;
			justify-content: space-between;
			flex-direction: row;
			gap: 18px;
		}
	}
	.sb-form-date-timezone {
		display: grid;
		gap: 16px;
		grid-template-columns: 1fr 1fr;
		margin: 16px 0;
		.ant-form-item {
			margin-bottom: 0;
		}

		& > :first-child {
			grid-column: span 2;
		}

		& > :nth-child(2),
		& > :nth-child(3) {
			grid-column: span 1;
		}
	}
`;

export default StyledReleaseScheduleForm;

