// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectSelectedWorkout } from "store/selectors";
// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { Paragraph2 } from "themes/default/_typography";
import { LiveIcon } from "components/Icons";

// -----------------------------------------------------------------------------
// Utils and Styles
// -----------------------------------------------------------------------------
import StyledReleaseScheduleForm from "./styles";
import DateTimeTimezone from "components/DateTimeTimezone";
import { useGetDomainGymQuery } from "store/api/api";
import { useParams } from "react-router";
import { defaultTimezone } from "helpers/constants";
import usePrimaryColor from "helpers/hooks/usePrimaryColor";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const ReleaseScheduleForm = ({ nextStep, previousStep, values }) => {
	const [form] = Form.useForm();
	const selectedWorkout = useSelector(selectSelectedWorkout);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const domainGymId = domainGymResponse.data?.id;
	const [currentDatetime, setCurrentDatetime] = useState(null);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
	const primaryColor = usePrimaryColor();

	const getPayload = () => {
		const values = form.getFieldsValue();
		const { workoutDate, workoutTime, workoutTimezone } = values;
		if (!workoutDate || !workoutTime || !workoutTimezone) {
			return {
				gym_id: domainGymId,
				timezone: workoutTimezone,
				currently_streaming: false,
			};
		}
		const day = dayjs(workoutDate).startOf("day").format("MM/DD/YYYY");
		const dayTime = dayjs(day + " " + workoutTime, "MM/DD/YYYY HH:mm");
		const iso = dayTime.format("YYYY-MM-DDTHH:mm:ss");
		return {
			gym_id: domainGymId,
			workout_id: selectedWorkout?.id,
			start_time: iso,
			timezone: workoutTimezone,
			time_zone: workoutTimezone,
			currently_streaming: false,
			workoutTime,
		};
	};
	const onFinish = async () => {
		setIsSubmitDisabled(true);
		const payload = getPayload();
		nextStep(payload);
	};

	const onPreviousStep = () => {
		setIsSubmitDisabled(false);
		const payload = getPayload();
		previousStep(payload);
	};

	return (
		<StyledReleaseScheduleForm primaryColor={primaryColor}>
			<div className="sb-release-schedule-form">
				<Form
					layout="vertical"
					onFinish={onFinish}
					form={form}
					initialValues={{
						workoutDate: values.start_time ? dayjs(values.start_time) : undefined,
						workoutTime: values.workoutTime,
						workoutTimezone: values.timezone || defaultTimezone,
					}}
				>
					<div className="sb-form-date-timezone">
						<DateTimeTimezone form={form} currentDatetime={currentDatetime} setCurrentDatetime={setCurrentDatetime} />
					</div>

					<div className="sb-release-schedule-form__notice">
						<LiveIcon fill={primaryColor} />
						<Paragraph2>
							Live workouts require you to log in and start the workout at the selected time and date.
						</Paragraph2>
					</div>
					<div className="sb-release-schedule-form__actions">
						<Button
							onClick={onPreviousStep}
							className="sb-release-schedule-form__form__button secondary-filled"
							chevronLeft
							uppercase
						>
							<Paragraph2>Back</Paragraph2>
						</Button>
						<Button
							type="submit"
							disabled={isSubmitDisabled}
							className="sb-release-schedule-form__form__button primary-filled"
							saveIcon
							uppercase
						>
							<Paragraph2>Schedule</Paragraph2>
						</Button>
					</div>
				</Form>
			</div>
		</StyledReleaseScheduleForm>
	);
};

export default ReleaseScheduleForm;

