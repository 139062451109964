import { useParams } from "react-router-dom";
import { useGetDomainGymQuery } from "store/api/api";

export default function usePrimaryColor() {
	const { gym } = useParams();
	const { data: domainGym } = useGetDomainGymQuery(gym);
	const primaryColor = domainGym?.brand_color1 || "#ff865c";
	return primaryColor;
}

