// -----------------------------------------------------------------------------
// Libraries
// -----------------------------------------------------------------------------
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRecoilValue } from "recoil";
import Sentry from "./instrument";

// -----------------------------------------------------------------------------
// Components and Pages
// -----------------------------------------------------------------------------
import { LoadingMask } from "components/Common/LoadingMask";
import CreatorCreationFlow from "pages/CreatorCreationFlow";
import { GymSearch } from "pages/GymSearch";
import Dashboard from "pages/Dashboard";
import Login from "pages/Login";
import LandingPage from "pages/Landing";
import NotFound from "pages/NotFound";
import AddPaymentMethod from "pages/ClientPortal/AddPaymentMethod";
import LogoutButton from "./components/Menu/LogoutButton";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import UploadProgress from "./components/UploadProgress";
import ConfirmEmailChange from "components/ConfirmEmailChange";
// -----------------------------------------------------------------------------
// Store
// -----------------------------------------------------------------------------
import { selectDomainGymId } from "store/selectors";
import {
	getDomainGym,
	getMemberships,
	getCategories,
	getProducts,
	getCreatorProduct,
	getCreatorAccount,
	getCurrentUser,
} from "store/slices";

// -----------------------------------------------------------------------------
// Others
// -----------------------------------------------------------------------------
import useInit from "./useInit";
import { getLocalUser } from "helpers/auth";
import { initializingAtom } from "./globalAtoms";
import Workouts from "pages/Dashboard/Workouts/Workouts";
import Members from "pages/Dashboard/Members/Members";
import EarningsPage from "pages/Dashboard/Earnings/Earnings";
import Analytics from "pages/Dashboard/Analytics/Analytics";
import Settings from "pages/Dashboard/Settings/Settings";
import OnDemand from "pages/ClientPortal/OnDemand";
import GetStarted from "pages/GetStarted";
import Privacy from "pages/Privacy";
import Terms from "pages/Terms";
import { PricingPlan } from "pages/Dashboard/PricingPlan";
import { OfflineBanner } from "components/OfflineBanner";
import "./antd-overrides.scss";
import ErrorPage from "components/ErrorPage";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
function App() {
	const dispatch = useDispatch();
	const domainGymId = useSelector(selectDomainGymId);

	// On page first load we need to get the domain gym data and any other data
	// which does not need the gym id
	useEffect(() => {
		const localUser = getLocalUser();

		if (localUser) {
			dispatch(getCurrentUser({ user_id: localUser.id }));
			dispatch(getMemberships({ user_type: "creator" }));
			dispatch(getMemberships({ user_type: "member" }));
			dispatch(getProducts());
		}
		dispatch(getDomainGym({ hostname: window.location.hostname }));
	}, [dispatch]);

	// After getting the domain gym data we fetch all the other initial data
	useEffect(() => {
		if (domainGymId) {
			dispatch(getCategories({ gym_id: domainGymId }));
			dispatch(getCreatorAccount({ gym_id: domainGymId }));
			dispatch(getCreatorProduct({ gym_id: domainGymId }));
		}
	}, [domainGymId, dispatch]);

	// TODO Recoil will be removed after the migration to redux is done
	const isInit = useRecoilValue(initializingAtom);

	useInit();

	if (isInit) {
		return <LoadingMask />;
	}

	let additionalRoutes = [
		{ path: "/gym-search", element: <GymSearch /> },
		{ path: "/creator-creation/*", element: <CreatorCreationFlow /> },

		{ path: "/login/:gym", element: <Login /> },
		{ path: "/login", element: <Login /> },
		{ path: "/login-preview/:gym", element: <Login preview={true} /> },
		{ path: "/login-preview", element: <Login preview={true} /> },
		{ path: "/add-payment-method", element: <AddPaymentMethod /> },
		{ path: "/reset-password/:userId/:subdomain/:token", element: <ResetPassword /> },
		{ path: "/logout", element: <LogoutButton /> },
	];

	return (
		<div>
			<OfflineBanner />
			<UploadProgress />
			<Router>
				<SentryRoutes>
					<Route exact path="/" element={<GetStarted />} />
					<Route exact path="/tos" element={<Terms />} />
					<Route exact path="/privacy" element={<Privacy />} />
					<Route exact path="/:gym" element={<LandingPage />} />
					<Route path="/:gym/dashboard/*" element={<Dashboard />}>
						<Route path={`workouts`} element={<Workouts />} />
						<Route path={`members`} element={<Members />} />
						<Route path={`earnings`} element={<EarningsPage />} />
						<Route path={`consumer`} element={<OnDemand />} />
						{/* <Route path={`payment-settings`} component={PaymentSettings} /> */}
						<Route path={`pricing-plan`} element={<PricingPlan />} />
						<Route path={`analytics`} element={<Analytics />} />
						<Route path={`settings`} element={<Settings />} />
					</Route>
					<Route path={`/:gym/confirm-email-change/:userId/:token`} element={<ConfirmEmailChange />} />

					{additionalRoutes.map((route) => (
						<Route {...route} key={route.path} />
					))}
					<Route exact path="/signup/:gym" element={<Login preview={false} type="signup" />} />
					<Route exact path={`/login-preview`} element={<Login preview={true} type="signup" />} />
					<Route exact path={`/error`} element={<ErrorPage />} />
					<Route path="*" element={<NotFound />} />
				</SentryRoutes>
			</Router>
		</div>
	);
}

export default App;
