import React from "react";
import { Form, Input, Flex, DatePicker, Collapse } from "antd";
import dayjs from "dayjs";
import { Button } from "components/Common/Button";

const EditPaymentMethods = ({ paymentMethod, onSubmit, loading }) => {
	const [form] = Form.useForm();

	const initialValues = {
		payment_method_id: paymentMethod.id,
		email: paymentMethod.billing_details.email,
		city: paymentMethod.billing_details.address.city,
		country: paymentMethod.billing_details.address.country,
		line1: paymentMethod.billing_details.address.line1,
		line2: paymentMethod.billing_details.address.line2,
		postal_code: paymentMethod.billing_details.address.postal_code,
		state: paymentMethod.billing_details.address.state,
		name: paymentMethod.billing_details.name || undefined,
		phone: paymentMethod.billing_details.phone,
		exp_month: dayjs(`${paymentMethod.card.exp_month}`, "M"),
		exp_year: dayjs(`${paymentMethod.card.exp_year}`, "YYYY"),
	};

	const handleFinish = (v) => {
		const values = {
			card:
				paymentMethod.type === "card"
					? {
							exp_month: v.exp_month ? v.exp_month.format("M") : paymentMethod.card.exp_month,
							exp_year: v.exp_year ? v.exp_year.format("YYYY") : paymentMethod.card.exp_year,
					  }
					: undefined,

			payment_method_id: paymentMethod.id,
			email: v.email,
			billing_details: {
				address: {
					city: v.city || undefined,
					country: v.country || undefined,
					line1: v.line1 || undefined,
					line2: v.line2 || undefined,
					postal_code: v.postal_code || undefined,
					state: v.state || undefined,
				},
				name: v.name,
				phone: v.phone || undefined,
			},
		};
		onSubmit(values);
	};

	return (
		<Form form={form} layout="vertical" initialValues={initialValues} onFinish={handleFinish}>
			{paymentMethod.type === "card" && (
				<Flex gap={16}>
					<Form.Item
						label="Expiry Month"
						name={"exp_month"}
						rules={[{ required: true, message: "Please input the expiration month!" }]}
						style={{ flex: 1 }}
					>
						<DatePicker format="MMM" style={{ borderRadius: 16, width: "100%" }} picker="month" />
					</Form.Item>
					<Form.Item
						label="Expiry Year"
						name={"exp_year"}
						rules={[{ required: true, message: "Please input the expiration year!" }]}
						style={{ flex: 1 }}
					>
						<DatePicker
							disabledDate={(current) => current && parseInt(current.format("YYYY")) < parseInt(dayjs().format("YYYY"))}
							format="YYYY"
							picker="year"
							style={{ borderRadius: 16, width: "100%" }}
						/>
					</Form.Item>
				</Flex>
			)}
			<Form.Item label="Email" name="email">
				<Input />
			</Form.Item>
			<Collapse
				accordion
				items={[
					{
						label: "Billing Details",
						children: (
							<>
								<Form.Item label="Name" name={"name"}>
									<Input />
								</Form.Item>
								<Form.Item label="Phone" name={"phone"}>
									<Input />
								</Form.Item>
								<Form.Item label="City" name={"city"}>
									<Input />
								</Form.Item>
								<Form.Item label="Country" name={"country"}>
									<Input />
								</Form.Item>
								<Form.Item label="Address Line 1" name={"line1"}>
									<Input />
								</Form.Item>
								<Form.Item label="Address Line 2" name={"line2"}>
									<Input />
								</Form.Item>
								<Form.Item label="Postal Code" name={"postal_code"}>
									<Input />
								</Form.Item>
								<Form.Item label="State" name={"state"}>
									<Input />
								</Form.Item>
							</>
						),
					},
				]}
			/>

			<Form.Item style={{ textAlign: "right", marginTop: 16 }}>
				<Button loading={loading} className="sb-create-workout-form__form__button primary-filled" htmlType="submit">
					Submit
				</Button>
			</Form.Item>
		</Form>
	);
};

export default EditPaymentMethods;

