// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { Input, Form } from "antd";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { Label2 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Styles
// -------------------------------------------------------------------------------
import StyledFormTextArea from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const { TextArea } = Input;

const FormTextArea = ({ name, label, rules, placeholder, rows }) => {
	const noSpacesRules = {
		validator: (_, value) => {
			if (!value) {
				// So it doesn't double validate
				return Promise.resolve();
			}
			return value.trim() ? Promise.resolve() : Promise.reject(new Error("This field is required."));
		},
	};
	const isFieldRequired = rules?.find(({ required }) => required);
	const ourRules = isFieldRequired ? [...rules, noSpacesRules] : rules;
	return (
		<StyledFormTextArea className="sb-form-text-area">
			<Form.Item required={false} name={name} label={<Label2>{label}</Label2>} rules={ourRules}>
				<TextArea rows={rows} placeholder={placeholder} data-id={name}/>
			</Form.Item>
		</StyledFormTextArea>
	);
};

export default FormTextArea;

