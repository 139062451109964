// -----------------------------------------------------------------------------
// Library and utils imports
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { useGetDomainGymQuery } from "store/api/api";
import { useParams } from "react-router";
import { variables } from "./_variables";
import { ConfigProvider } from "antd";

// -----------------------------------------------------------------------------
// Theme
// -----------------------------------------------------------------------------
const Default = ({ children }) => {
	const { gym } = useParams();
	const { data: domainGym } = useGetDomainGymQuery(gym, { skip: !gym });
	const [theme, setTheme] = useState({ colors: variables.colors, responsive: variables.responsive });

	useEffect(() => {
		if (domainGym) {
			const { brand_color1 } = domainGym;
			if (brand_color1 && CSS.supports("color", brand_color1)) {
				setTheme({
					colors: {
						...variables.colors,
						coral: brand_color1,
					},
					responsive: variables.responsive,
				});
			}
		}
	}, [domainGym]);

	return (
		<ConfigProvider
			theme={{
				hashed: false,
				token: {
					colorPrimary: domainGym?.brand_color1 || "#ff865c",
					borderRadius: 4,
					colorText: "#000",
					fontSize: 14,
				},
				components: {
					Input: {
						colorPrimary: domainGym?.brand_color1 || "#ff865c",
						controlHeight: 38,
						algorithm: true,
						borderRadius: 24,
						colorBgContainer: "#F9FAFB",
						controlHeightSM: 36,
						borderRadiusSM: 24,
					},
					Dropdown: {
						borderRadius: 4,
						padding: "24px",
					},
					Menu: {
						itemHeight: 56,
						height: 56,
						colorText: "#FFF",
					},
					Button: {
						colorPrimary: domainGym?.brand_color1 || "#ff865c",
						textTransform: "none",
						fontSize: 14,
					},
					Modal: {
						borderRadiusLG: 10,
						borderRadiusMD: 10,
						borderRadiusSM: 10,
					},
					Typography: {
						colorText: "#000",
					},
				},
			}}
		>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</ConfigProvider>
	);
};

export default Default;
