import React, { useEffect, useState } from "react";
import MuxUploader from "@mux/mux-uploader-react";
import MuxPlayer from "@mux/mux-player-react";
import { useGetMuxVideoUrlQuery } from "store/api/api";

function MuxUploadAndVideoPlayer({ loading, endpoint, onSuccess, playbackId, name, videoUploaded, onUploadStart }) {
	const [key, setKey] = useState(1);
	const { data } = useGetMuxVideoUrlQuery({ playback_id: playbackId });

	useEffect(() => {
		const muxUploader = document.querySelector("mux-uploader");

		function success(e) {
			if (onSuccess) {
				onSuccess(e);
			}
		}

		async function upload(e) {
			if (onUploadStart) {
				const success = await onUploadStart(e);
				if (!success) {
					// A hack to force rerender and disable upload
					setKey(key + 1);
				}
			}
		}
		muxUploader?.addEventListener("success", success);
		muxUploader?.addEventListener("uploadstart", upload);

		return () => {
			muxUploader?.removeEventListener("success", success);
			muxUploader?.removeEventListener("uploadstart", upload);
		};
	}, [onSuccess, onUploadStart, key]);

	if (videoUploaded) {
		return (
			<div style={{ padding: 32, width: "100%", textAlign: "center", border: "2px dashed lightgrey", marginBottom: 8 }}>
				Upload Complete!
			</div>
		);
	}

	if (loading) {
		return <div className="sb-upload-assets-form__upload__input">Loading...</div>;
	}

	if (endpoint) {
		return <MuxUploader key={key} endpoint={endpoint} />;
	}

	if (playbackId) {
		return (
			<MuxPlayer
				streamType="on-demand"
				playbackId={playbackId}
				metadata={{
					video_id: playbackId,
					video_title: name || "No Title Found",
					viewer_user_id: "Trainer Edit",
				}}
				tokens={{
					playback: data?.token,
					thumbnail: data?.thumbnail_token,
					storyboard: data?.storyboard_token,
				}}
			/>
		);
	}
	return null;
}

export default MuxUploadAndVideoPlayer;

