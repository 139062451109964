// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { Card, Modal, Button, notification, Typography } from "antd";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
import { Heading4, Paragraph14 } from "themes/default/_typography";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import { PencilIcon, TrashIcon, VisaIcon } from "components/Icons";
import StyledPaymentMethodCard from "./styles";
import { useStripeDetachPaymentMethodMutation, useStripeUpdatePaymentMethodMutation } from "store/api/api";
import EditPaymentMethods from "./EditPaymentMethods";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------

const PaymentMethodCard = ({ className, paymentMethod, refetch, clientSecret, ...other }) => {
	const [detachPaymentMethodTrigger, detachPaymentMethod] = useStripeDetachPaymentMethodMutation();
	const [updatePaymentMethodTrigger, updatePaymentMethod] = useStripeUpdatePaymentMethodMutation();
	const [confirmOpen, setConfirmOpen] = React.useState(false);
	const [editModalOpen, setEditModalOpen] = React.useState(false);

	const handleDetachPaymentMethod = async () => {
		try {
			await detachPaymentMethodTrigger({ payment_method_id: paymentMethod.id }).unwrap();
			await refetch?.();
			setConfirmOpen(false);
		} catch (error) {
			notification.error({
				message: "There was an error detaching the payment method",
			});
		}
	};

	const onSubmit = async (values) => {
		try {
			await updatePaymentMethodTrigger(values).unwrap();
			await refetch?.();
			setEditModalOpen(false);
		} catch (error) {
			notification.error({
				message: "There was an error updating the payment method",
			});
		}
	};

	return (
		<StyledPaymentMethodCard className={`${className} sb-payment-method-card`} {...other}>
			<Modal
				title="Confirm"
				open={confirmOpen}
				onCancel={() => setConfirmOpen(false)}
				footer={
					<div className="sb-confirm-form__actions">
						<Button onClick={() => setConfirmOpen(false)}>Go Back</Button>
						<Button loading={detachPaymentMethod.isLoading} onClick={handleDetachPaymentMethod} type="primary">
							Yes
						</Button>
					</div>
				}
			>
				<Typography.Paragraph style={{ marginBottom: 16 }}>
					Are you sure you want to detach this payment method?
				</Typography.Paragraph>
			</Modal>
			<Modal title="" open={editModalOpen} onCancel={() => setEditModalOpen(false)} footer={null}>
				<EditPaymentMethods loading={updatePaymentMethod.isLoading} paymentMethod={paymentMethod} onSubmit={onSubmit} />
			</Modal>

			<Card hoverable>
				<div className="sb-payment-method-card__inner">
					<div className="sb-payment-method-card__inner__left">
						<div className="sb-payment-method-card__inner__left__icon">
							<VisaIcon />
						</div>
						<div className="sb-payment-method-card__inner__left__data">
							<Heading4>**** **** **** {paymentMethod?.card.last4 || 1234}</Heading4>
							<Paragraph14>
								Expires {paymentMethod.card.exp_month || 12}/{paymentMethod.card.exp_year || 2021}
							</Paragraph14>
						</div>
					</div>
					<div className="sb-payment-method-card__inner__right">
						<div className="sb-payment-method-card__inner__right__action">
							<PencilIcon
								onClick={() => {
									setEditModalOpen(true);
								}}
							/>
						</div>
						<div className="sb-payment-method-card__inner__right__action">
							<TrashIcon
								onClick={() => {
									setConfirmOpen(true);
								}}
							/>
						</div>
					</div>
				</div>
			</Card>
		</StyledPaymentMethodCard>
	);
};

export default PaymentMethodCard;

