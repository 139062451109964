import styled from "styled-components";

const StyledWorkoutMusicForm = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 200px;
	width: 100%;

	.sb-workout-music-form {
		width: 100%;

		&__title {
			margin-bottom: 24px;
		}

		&__actions {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 60px;
		}
	}
`;

export default StyledWorkoutMusicForm;

