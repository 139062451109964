// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { DatePicker, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import GymBanner from "components/UI/GymBanner";
import WorkoutsList from "components/Workouts/WorkoutsList";
import { CategoriesFilter } from "components/Categories/CategoriesFilter";

// -----------------------------------------------------------------------------
// Actions and helpers
// -----------------------------------------------------------------------------
import { updateMembersWorkoutsSearchTerm } from "store/slices";
import usePrimaryColor from "helpers/hooks/usePrimaryColor";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const OnDemand = () => {
	const { RangePicker } = DatePicker;
	const dispatch = useDispatch();
	const primaryColor = usePrimaryColor();

	const [dateRange, setDateRange] = useState({
		startDate: dayjs().startOf("year"),
		endDate: dayjs().endOf("year"),
	});

	const onDateRangeChange = (dates) => {
		if (dates) {
			setDateRange({
				startDate: dates[0],
				endDate: dates[1],
			});
		}
	};

	const handleSearch = (e) => {
		const { value } = e.target;
		dispatch(updateMembersWorkoutsSearchTerm(!value || value.trim() === "" ? "" : value.toLowerCase()));
	};

	return (
		<div className="sb-on-demand-container">
			<GymBanner />
			<div className="sb-on-demand-container__body">
				<div className="sb-on-demand-container__body__head">
					<h1 className="sb-on-demand-container__body__head__title">Explore Videos</h1>
					<RangePicker
						defaultValue={[dateRange.startDate, dateRange.endDate]}
						allowClear={false}
						popupStyle={{ zIndex: 99999, overflow: "scroll", }}
						presets={[
							{
								label: "Today",
								value: [dayjs().startOf("day"), dayjs().endOf("day")],
							},
							{
								label: "This Week",
								value: [dayjs().startOf("week"), dayjs().endOf("week")],
							},
							{
								label: "Last Month",
								value: [dayjs().startOf("month").subtract(1, "months"), dayjs().startOf("month").subtract(1, "days")],
							},
							{
								label: "This Month",
								value: [dayjs().startOf("month"), dayjs().endOf("month")],
							},
							{
								label: "Last Year",
								value: [dayjs().startOf("year").subtract(1, "years"), dayjs().startOf("year").subtract(1, "days")],
							},
							{
								label: "This Year",
								value: [dayjs().startOf("year"), dayjs().endOf("year")],
							},
						]}
						onChange={onDateRangeChange}
						placement="bottomRight"
					/>
				</div>
				<div className="sb-on-demand-container__body__filters">
					<CategoriesFilter />
					<Input
						className="sb-on-demand-container__body__filters__search-input"
						prefix={<SearchOutlined />}
						onChange={handleSearch}
						placeholder="Search Workouts..."
						style={{ minWidth: 256, "--primary-color": primaryColor }}
					/>
				</div>
				<WorkoutsList dateRange={dateRange} />
			</div>
		</div>
	);
};

export default OnDemand;
