import styled from "styled-components";

const StyledCreateWorkoutModal = styled.div`
	.sb-create-workout-modal {
		&__header {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 16px;
			margin-bottom: 32px;

			&__steps {
				margin: 24px auto 8px auto;
			}
		}
	}
	.sb-button,
	.ant-btn {
		min-width: 220px;
		@media (max-width: 768px) {
			min-width: 100%;
		}
	}
	.sb-workout-music-form__actions,
	.sb-release-schedule-form__actions,
	.sb-workout-details-form__form__actions {
		@media (max-width: 768px) {
			flex-wrap: wrap;
		}
	}
`;

export default StyledCreateWorkoutModal;

