import styled from "styled-components";

const StyledFormTagSelect = styled.div`
	width: 100%;

	.ant-select:not(.ant-select-customize-input) {
		.ant-select-selector {
			border: 1px solid ${({ theme }) => theme.colors.iron};
			border-radius: 24px;
			min-height: 50px;
			padding: 4px 10px;
		}
	}

	.ant-select-selection-item {
		background: ${({ theme }) => theme.colors.athensGray};
		padding: 0 14px;
		height: 34px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100px !important;
		border: 1px solid #e3e5e7;

		.ant-select-selection-item-content {
			margin-right: 8px;
			font-style: normal;
			font-weight: 700;
			font-size: 12px;
			line-height: 22px;
			letter-spacing: 0.1px;
			text-transform: uppercase;
		}

		.ant-select-selection-item-remove {
			color: ${({ theme }) => theme.colors.coral};
			font-size: 12px;
		}
	}

	.ant-select-selection-placeholder {
		padding: 0 10px;
	}
`;

export default StyledFormTagSelect;

