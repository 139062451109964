import styled from "styled-components";

const StyledAnalytics = styled.div`
	background-color: ${({ theme }) => theme.colors.porcelain};
	padding: 44px 40px;
	min-height: calc(100vh - 56px);
`;

export default StyledAnalytics;

