// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React, { useState, useEffect } from "react";

// -------------------------------------------------------------------------------
// Store
// -------------------------------------------------------------------------------
import { useGetGymMembersByDateQuery } from "store/api/api";

// -------------------------------------------------------------------------------
// Components
// -------------------------------------------------------------------------------
import { DonutChart } from "components/Common/Charts/DonutChart";
import { LegendCard } from "components/Common/Cards/LegendCard";
import { LoadingMask } from "components/Common/LoadingMask";

// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledMembersSubscribersDonutChart from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const MembersSubscribersDonutChart = (props) => {
	const { dateRange } = props;
	const [donutChartData, setDonutChartData] = useState(null);

	const gymMembersResponse = useGetGymMembersByDateQuery({
		start_date: dateRange.startDate.format("YYYY-MM-DD"),
		end_date: dateRange.endDate.format("YYYY-MM-DD"),
	});

	useEffect(() => {
		if (gymMembersResponse.data) {
			const { activeCount, trialCount, cancelledCount } = gymMembersResponse.data.subscribers.reduce(
				(acc, member) => {
					const { subscription_status } = member;
					if (subscription_status === "ACTIVE") {
						acc.activeCount += 1;
					} else if (subscription_status === "TRIAL") {
						acc.trialCount += 1;
					} else if (subscription_status === "CANCELLED") {
						acc.cancelledCount += 1;
					}
					return acc;
				},
				{ activeCount: 0, trialCount: 0, cancelledCount: 0 }
			);

			setDonutChartData([
				{
					name: "Active Subscribers",
					value: activeCount,
					fill: "#00008b",
				},
				{
					name: "Free Members",
					value: trialCount,
					fill: "#6fa2f2",
				},
				{
					name: "Cancelled Members",
					value: cancelledCount,
					fill: "#d3d3d3",
				},
			]);
		}
	}, [gymMembersResponse]);

	const isLoading = gymMembersResponse.isFetching || gymMembersResponse.isLoading || !donutChartData;

	if (donutChartData) {
		const [activeData, trialData, cancelledData] = donutChartData;

		return (
			<StyledMembersSubscribersDonutChart className="sb-members-subscribers-donut-chart">
				{isLoading ? (
					<LoadingMask />
				) : (
					<>
						<div className="sb-members-page__subscribers__charts__donut__chart__chart">
							<DonutChart size={200} labelSize={20} data={donutChartData} />
						</div>
						<div className="sb-members-page__subscribers__charts__donut__chart__cards">
							<LegendCard amount={activeData.value} label="Total Subscribed Members" color={activeData.fill} />
							<LegendCard amount={trialData.value} label="Total Trial Members" color={trialData.fill} />
							<LegendCard amount={cancelledData.value} label="Cancelled Members" color={cancelledData.fill} />
						</div>
					</>
				)}
			</StyledMembersSubscribersDonutChart>
		);
	}

	return <LoadingMask />;
};

export default MembersSubscribersDonutChart;

