import styled from "styled-components";

const StyledWorkoutDetailsForm = styled.div`
	.sb-workout-details-form {
		&__form {
			margin: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 24px;

			&__field {
				width: 100%;

				&__label {
					&__main {
						margin-right: 6px;
					}
				}

				#workoutEquipment {
					color: ${({ theme }) => theme.colors.coral};
				}
			}

			&__activation-schedule {
				display: flex;
				align-items: center;
				justify-content: space-between;

				&__label {
					&__main {
						margin-right: 6px;
					}
				}
			}

			&__actions {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 16px;
			}
		}
	}
	.sb-form-date-timezone {
		display: grid;
		gap: 16px;
		grid-template-columns: 1fr 1fr;
		margin-top: 16px;
		.ant-form-item {
			margin-bottom: 0;
		}

		& > :first-child {
			grid-column: span 2;
		}

		& > :nth-child(2),
		& > :nth-child(3) {
			grid-column: span 1;
		}
	}
`;

export default StyledWorkoutDetailsForm;

