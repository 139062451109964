import styled from "styled-components";

const StyledPlanCard = styled.div`
	min-width: 280px;
	max-width: 400px;
	flex: 1;

	.ant-card {
		height: 100%;

		.ant-card-body {
			position: relative;
			padding: 16px 16px 32px 16px;
		}
	}

	.sb-plan-card {
		&__icon {
			position: absolute;
			width: 48px;
			height: 48px;
			left: calc(50% - 24px);
			top: -24px;
			background: ${({ theme }) => theme.colors.black};
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: ${({ theme }) => theme.colors.coral};
		}

		&__title {
			margin: 38px 0 14px 0;
			text-align: center;

			p {
				margin: 0;
			}
		}

		&__description {
			text-align: center;
			height: 80px;
		}

		&__price {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			margin-bottom: 10px;

			span {
				margin: 0 0 8px 4px;
			}
		}

		&__price-description {
			text-align: center;
			margin-bottom: 16px;
		}

		&__list {
			display: flex;
			flex-direction: column;
			gap: 8px;

			&__item {
				display: flex;
				align-items: center;
				gap: 8px;

				&__icon {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 20px;
					height: 20px;
					color: ${({ theme }) => theme.colors.coral};
				}
			}
		}
	}
`;

export default StyledPlanCard;

