import React from "react";
import AntDMenu from "antd/lib/menu";
import PropTypes from "prop-types";
import usePrimaryColor from "helpers/hooks/usePrimaryColor";

function Menu(props) {
	const { options, defaultSelectedKeys, defaultOpenKeys, onClick } = props;
	const primaryColor = usePrimaryColor();

	const menuItems = options.map((option) => ({
		"data-id": `menu-nav-link-${option.id}`,
		disabled: option.disabled,
		key: option.id,
		icon: option.icon,
		label: option.text,
		style: { margin: 0, "--primary-color": primaryColor },
		className: "sb-menu-item",
	}));

	return (
		<AntDMenu
			onClick={onClick}
			defaultOpenKeys={defaultOpenKeys}
			selectedKeys={defaultSelectedKeys}
			mode="inline"
			items={menuItems}
		></AntDMenu>
	);
}

Menu.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			icon: PropTypes.any,
		})
	),
	defaultSelectedKeys: PropTypes.arrayOf(PropTypes.string),
};

export default Menu;
