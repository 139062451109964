import React, { useState, useRef } from "react";
import {
	Alert,
	Tabs,
	Form,
	Input,
	Flex,
	Button as AntButton,
	ConfigProvider,
	Typography,
	Space,
	notification,
} from "antd";
import { MailOutlined, PhoneOutlined, InfoCircleOutlined } from "@ant-design/icons";
import "./styles.scss";
import {
	useSendEmailChangeLinkMutation,
	useSendOtpToPhoneNumberMutation,
	useVerifyOtpAndChangePhoneNumberMutation,
} from "store/api/api"; // Update the path as necessary
import usePrimaryColor from "helpers/hooks/usePrimaryColor";
import { useRecoilValue } from "recoil";
import { userAtom } from "globalAtoms";
import { Button } from "components/Common/Button";
import { validateEmail } from "helpers/Form/commonFormValidations";
import PhoneInput from "antd-phone-input";
import { getUser } from "useInit";
import { useSetRecoilState } from "recoil";
import { useParams } from "react-router";

const { TabPane } = Tabs;

const CancelButton = (props) => {
	const primaryColor = usePrimaryColor();
	return (
		<ConfigProvider
			theme={{ token: { colorPrimary: primaryColor }, components: { Button: { colorPrimary: primaryColor } } }}
		>
			<AntButton
				{...props}
				style={{ ...props.style, borderColor: primaryColor, color: primaryColor }}
				htmlType="button"
			>
				Cancel
			</AntButton>
		</ConfigProvider>
	);
};

const EditProfile = ({ onCancel }) => {
	const [emailForm] = Form.useForm();
	const [phoneForm] = Form.useForm();
	const [otpForm] = Form.useForm();
	const ref = useRef();
	const { gym } = useParams();
	const [sendEmailChangeLinkTrigger, sendEmailChangeLink] = useSendEmailChangeLinkMutation();
	const [emailSent, setEmailSent] = useState(false);
	const [isOTPModalVisible, setIsOTPModalVisible] = useState(false);
	const [sendOtpToPhoneNumberTrigger, sendOtpToPhoneNumber] = useSendOtpToPhoneNumberMutation();
	const [verifyOtpTrigger, verifyOtpAndChangePhoneNumber] = useVerifyOtpAndChangePhoneNumberMutation();
	const { email_address: emailAddress, phone_number: phoneNumber } = useRecoilValue(userAtom) ?? {};
	const setUser = useSetRecoilState(userAtom);

	const handleEmailSubmit = async (values) => {
		try {
			setEmailSent(false);
			const domain = `${window.location.origin}/${gym}`;
			const payload = {
				new_email_address: values.new_email,
				domain,
			};
			await sendEmailChangeLinkTrigger(payload).unwrap();
			setEmailSent(true);
		} catch (error) {
			notification.error({
				message: error?.data?.message || "Error sending email change link. Please try again later.",
			});
		}
	};

	const handlePhoneSubmit = async (values) => {
		try {
			const newPhoneNumber = `+${values.new_phone_number?.countryCode}${values.new_phone_number?.areaCode}${values.new_phone_number?.phoneNumber}`;
			const payload = {
				new_phone_number: newPhoneNumber,
			};
			await sendOtpToPhoneNumberTrigger(payload).unwrap();
			setIsOTPModalVisible(true);
		} catch (error) {
			notification.error({
				message: error?.data?.message || "Error sending OTP. Please try again later.",
			});
		}
	};

	const handleOtpSubmit = async (values) => {
		try {
			const newPhoneNumber = `+${phoneForm.getFieldValue("new_phone_number")?.countryCode}${
				phoneForm.getFieldValue("new_phone_number")?.areaCode
			}${phoneForm.getFieldValue("new_phone_number")?.phoneNumber}`;
			const payload = {
				phone_number: newPhoneNumber,
				code: values.otp,
			};
			await verifyOtpTrigger(payload).unwrap();
			setIsOTPModalVisible(true);
			notification.success({
				message: "Phone number updated successfully!",
			});
			await getUser(setUser);
			onCancel?.();
		} catch (error) {
			notification.error({
				message: error?.data?.message || "Error updating phone number. Please try again later.",
			});
		}
	};

	return (
		<Tabs
			defaultActiveKey="email"
			tabBarStyle={{ display: "flex", justifyContent: "space-around" }}
			className="edit-profile-tabs"
		>
			<TabPane
				tab={
					<Flex justify="center" align="center">
						<MailOutlined />
						<Typography.Text className="tab-label">Email</Typography.Text>
					</Flex>
				}
				key="email"
			>
				<Form
					requiredMark={false}
					layout="vertical"
					form={emailForm}
					initialValues={{
						current_email: emailAddress,
					}}
					onFinish={handleEmailSubmit}
				>
					{emailSent && (
						<Alert
							message="Verification Email Sent"
							description={
								<Space direction="vertical">
									<Typography.Text>
										We have sent a verification email to your new email address. Please click the link in the email to
										confirm your new email address.
									</Typography.Text>
									<Typography.Text style={{ color: "#0858D9" }}>
										New Email - {emailForm.getFieldValue("new_email")}
									</Typography.Text>
								</Space>
							}
							type="info"
							showIcon
							icon={<InfoCircleOutlined />}
							closable
						/>
					)}
					<Form.Item name="current_email" label="Current Email">
						<Input disabled placeholder="Enter Current Email" />
					</Form.Item>
					<Form.Item
						name="new_email"
						label="New Email"
						rules={[
							{ required: true, message: "Please input your new email!" },
							({ getFieldValue }) => ({
								validator: (_, value) => {
									if (value === getFieldValue("current_email")) {
										return Promise.reject(new Error("Current email and new email cannot be same!"));
									}
									if (!value?.trim()) {
										return Promise.resolve();
									}
									const isInValid = validateEmail(value);
									if (!isInValid) {
										return Promise.resolve();
									}
									return Promise.reject(new Error(isInValid));
								},
							}),
						]}
					>
						<Input type="email" placeholder="Enter New Email" />
					</Form.Item>
					<Form.Item
						name="confirm_new_email"
						label="Confirm New Email"
						dependencies={["new_email"]}
						rules={[
							{ required: true, message: "Please confirm your new email!" },
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue("new_email") === value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error("The two emails do not match!"));
								},
							}),
						]}
					>
						<Input type="email" placeholder="Re-Enter New Email" />
					</Form.Item>
					<div className="sb-confirm-form__actions">
						<CancelButton style={{ height: 40, textTransform: "none", fontSize: 16 }} size="small" onClick={onCancel}>
							<span>Cancel</span>
						</CancelButton>
						<Button
							htmlType="submit"
							style={{ height: 40, textTransform: "none" }}
							className="sb-release-schedule-form__form__button primary-filled"
							type="primary"
							loading={sendEmailChangeLink.isLoading}
							disabled={emailSent || sendEmailChangeLink.isLoading}
						>
							Submit
						</Button>
					</div>
				</Form>
			</TabPane>
			<TabPane
				tab={
					<Flex justify="center" align="center">
						<PhoneOutlined />
						<Typography.Text className="tab-label">Phone Number</Typography.Text>
					</Flex>
				}
				key="phone"
			>
				{!isOTPModalVisible && (
					<Form
						requiredMark={false}
						initialValues={{
							phone_number: phoneNumber,
						}}
						layout="vertical"
						form={phoneForm}
						onFinish={handlePhoneSubmit}
					>
						<Form.Item name="phone_number" label="Current Phone Number">
							<PhoneInput placeholder="Enter Phone Number" disabled size="small" enableSearch />
						</Form.Item>
						<Form.Item
							name="new_phone_number"
							label="New Phone Number"
							rules={[
								{ required: true, message: "Please input your new phone number!" },
								({ getFieldValue }) => ({
									validator: (_, v) => {
										const value = `+${v?.countryCode}${v?.areaCode}${v?.phoneNumber}`;
										if (value === getFieldValue("phone_number")) {
											// return Promise.reject(new Error("Current phone number and new phone cannot be same!"));
										}
										if (v?.valid?.()) {
											return Promise.resolve();
										}
										return Promise.reject(new Error("Please enter a valid phone number!"));
									},
								}),
							]}
						>
							<PhoneInput placeholder="Enter New Phone Number" size="small" enableSearch />
						</Form.Item>
						<Form.Item
							name="confirm_new_phone_number"
							label="Confirm New Phone Number"
							rules={[
								{ required: true, message: "Please confirm your new phone number!" },
								({ getFieldValue }) => ({
									validator(_, v) {
										const value = `+${v?.countryCode}${v?.areaCode}${v?.phoneNumber}`;
										const newPhoneNumberObject = getFieldValue("new_phone_number");
										const newPhoneNumber = `+${newPhoneNumberObject?.countryCode}${newPhoneNumberObject?.areaCode}${newPhoneNumberObject?.phoneNumber}`;
										if (!value || newPhoneNumber === value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error("The two phone numbers do not match!"));
									},
								}),
							]}
						>
							<PhoneInput placeholder="Re-Enter New Phone Number" size="small" enableSearch />
						</Form.Item>
						<div className="sb-confirm-form__actions">
							<CancelButton style={{ height: 40, textTransform: "none", fontSize: 16 }} onClick={onCancel}>
								Cancel
							</CancelButton>
							<Button
								htmlType="submit"
								style={{ height: 40, textTransform: "none" }}
								className="sb-release-schedule-form__form__button primary-filled"
								type="primary"
								loading={sendOtpToPhoneNumber.isLoading}
							>
								Submit
							</Button>
						</div>
					</Form>
				)}
				{isOTPModalVisible && (
					<Form
						style={{ padding: "24px 0 0" }}
						requiredMark={false}
						layout="vertical"
						form={otpForm}
						onFinish={handleOtpSubmit}
						initialValues={{
							phone_number: phoneNumber,
						}}
					>
						<Typography.Paragraph style={{ textAlign: "center", fontSize: 15, marginBottom: 16 }}>
							Enter your OTP
						</Typography.Paragraph>
						<Form.Item
							className="sb-otp"
							name="otp"
							label=""
							rules={[{ required: true, message: "Please input your new phone number!" }]}
						>
							<Input.OTP ref={ref} placeholder="0" length={6} />
						</Form.Item>
						<Typography.Paragraph className="caption" color="#55575B">
							Your one time password will expire in 5 minutes
						</Typography.Paragraph>
						<div className="sb-confirm-form__actions">
							<CancelButton
								htmlType="button"
								style={{ height: 40, textTransform: "none", fontSize: 16 }}
								onClick={onCancel}
							>
								Cancel
							</CancelButton>
							<Button
								htmlType="submit"
								className="sb-release-schedule-form__form__button primary-filled"
								style={{ height: 40, textTransform: "none" }}
								type="primary"
								loading={verifyOtpAndChangePhoneNumber.isLoading}
							>
								Submit
							</Button>
						</div>
					</Form>
				)}
			</TabPane>
		</Tabs>
	);
};

export default EditProfile;

