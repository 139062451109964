import styled from "styled-components";

const StyledUploadAssetsForm = styled.div`
	.sb-upload-assets-form {
		&__upload {
			margin: 32px 0;

			&__input {
				margin-bottom: 8px;
			}
		}

		&__form {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: 8px;

			&__button {
				margin: 8px 0;
			}
		}
	}
`;

export default StyledUploadAssetsForm;

