// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import dayjs from "dayjs";
import React, { useState } from "react";
import { DatePicker } from "antd";

// -----------------------------------------------------------------------------
// Components
// -----------------------------------------------------------------------------
import { MembersSubscribersDonutChart } from "components/Members/MembersSubscribersDonutChart";
import { MembersSubscribersStackedBarChart } from "components/Members/MembersSubscribersStackedBarChart";
import { MembersTable } from "components/Members/MembersTable";
import { Heading3 } from "themes/default/_typography";
import moment from "moment";

// -----------------------------------------------------------------------------
// Styles
// -----------------------------------------------------------------------------
import StyledCategories from "./styles";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const Members = () => {
	const { RangePicker } = DatePicker;
	const [dateRange, setDateRange] = useState({
		startDate: dayjs().startOf("month"),
		endDate: dayjs().endOf("month"),
	});

	const onDateRangeChange = (dates) => {
		if (dates) {
			setDateRange({
				startDate: dates[0],
				endDate: dates[1],
			});
		}
	};

	return (
		<StyledCategories className="sb-members-page">
			<div className="sb-members-page__subscribers">
				<div className="sb-members-page__subscribers__heading">
					<Heading3>Subscribers</Heading3>
					<div className="sb-members-page__subscribers__heading__date-filter">
						<RangePicker
							defaultValue={[dateRange.startDate, dateRange.endDate]}
							allowClear={false}
							popupStyle={{ zIndex: 99999 }}
							presets={[
								{
									label: "Last Month",
									value: [dayjs().startOf("month").subtract(1, "months"), dayjs().startOf("month").subtract(1, "days")],
								},
								{
									label: "This Month",
									value: [dayjs().startOf("month"), dayjs().endOf("month")],
								},
								{
									label: "Last Year",
									value: [dayjs().startOf("year").subtract(1, "years"), dayjs().startOf("year").subtract(1, "days")],
								},
								{
									label: "This Year",
									value: [dayjs().startOf("year"), dayjs().endOf("year")],
								},
							]}
							onChange={onDateRangeChange}
						/>
					</div>
				</div>
				<div className="sb-members-page__subscribers__charts">
					<div className="sb-members-page__subscribers__charts__donut">
						<MembersSubscribersDonutChart dateRange={dateRange} />
					</div>
					<div className="sb-members-page__subscribers__charts__stacked-bar-chart">
						<MembersSubscribersStackedBarChart
							dateRange={{
								startDate: moment(dateRange.startDate.toDate()),
								endDate: moment(dateRange.endDate.toDate()),
							}}
						/>
					</div>
				</div>
				<div className="sb-members-page__subscribers__table">
					<MembersTable dateRange={dateRange} />
				</div>
			</div>
		</StyledCategories>
	);
};

export default Members;
