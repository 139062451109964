// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useRecoilValue } from "recoil";
import { Button, Layout } from "antd";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { selectCreatorProduct } from "store/selectors";
import { Button as CommonButton } from "components/Common/Button";
import { Paragraph5, Heading11, Span2, Paragraph25 } from "themes/default/_typography";
import useLogout from "../../../utils/useLogout";
import defaultHeader from "../../../images/default-header.jpeg";
import devicesImage from "../../../images/devices.png";
import googlePlayImage from "../../../images/google-play.png";
import appStoreImage from "../../../images/app-store.png";
import iconsImage from "../../../images/landing-apps.png";
import checkIcon from "../../../images/check-icon-white.svg";
import { Link } from "react-router-dom";
import Ajax from "../../../utils/api";
import ajaxRoutes from "../../../utils/ajaxRoutes";
import { userAtom } from "../../../globalAtoms";
import { formatMoney } from "helpers/generic";
import { useNavigate, useParams } from "react-router";
import { useGetDomainGymQuery } from "store/api/api";
import { MusicIcon } from "components/Icons/MusicIcon";
import { ComputerIcon } from "components/Icons/ComputerIcon";
import { FullLogoIcon, LiveIcon } from "components/Icons";
const { Footer } = Layout;

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const Landing = (props) => {
	const { gym } = useParams();
	const logoutHook = useLogout(props.history, gym);
	// TODO CHECK LANDING PAGE HERE
	const navigate = useNavigate();
	const [landingData, setLandingData] = React.useState(null);
	const creatorProduct = useSelector(selectCreatorProduct);
	const user = useRecoilValue(userAtom);
	let gymId = props.gymId;
	let gymName = user?.gym?.name || "";

	const { data: domainGymData } = useGetDomainGymQuery(gym);

	useEffect(() => {
		// Update the document title using the browser API
		document.title = `SWEATBASE${gymName?.length > 0 ? " - " + gymName : ""}`;
	});

	if (gymId === "creator") {
		gymId = user?.userAssociations?.gym_id;
	}

	if (window.location.href.includes("landing-preview")) {
		gymId = domainGymData?.gym_id;
	}

	const getPageData = React.useCallback(async () => {
		const res = await Ajax.call(ajaxRoutes.GET_PAGES(gymId));
		let landingData = null;

		if (res) {
			landingData = res.find((page) => page.type === "landing");
		}

		if (landingData) {
			setLandingData(landingData);
		}

		if (res && res.length > 0 && !landingData) {
			setLandingData(res.find((r) => r.type === "landing"));
		}
	}, [gymId]);

	useEffect(() => {
		if (gymId && !landingData) {
			getPageData();
		}
	}, [getPageData, gymId, landingData]);

	useEffect(() => {
		//TODO: CHECK IF THIS AFFECTS ADMIN TYPES
		if (gym && user) {
			if (user?.gym?.subdomain !== gym && user?.user_type !== "admin") {
				logoutHook.logout();
			} else {
				if (!props.preview) {
					navigate("dashboard/on-demand/");
					window.location.reload();
				}
			}
		}
	}, [user, gym]);

	const renderLoginButton = () => {
		if (!user) {
			return (
				<div
					data-id="login-button"
					onClick={() => navigate(`/login/${gym}`)}
					style={{
						padding: "0px",
						margin: "0px",
						width: "134px",
						height: "50px",
						backgroundColor: "transparent",
						color: "white",

						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						cursor: "pointer",
						"&:hover": {
							backgroundColor: "white",
							color: "black",
						},
					}}
				>
					<p
						style={{
							color: "white",
							fontWeight: 700,
							lineHeight: "18px",
							letterSpacing: "2px",
							fontSize: "18px",

							margin: "0px",
						}}
					>
						LOGIN
					</p>
				</div>
			);
		} else {
			return (
				<div>
					<Button className="sb-landing__header__inner__head__login-button" onClick={() => navigate("dashboard")}>
						dashboard
					</Button>
					<Button className="sb-landing__header__inner__head__login-button" onClick={() => logoutHook.logout()}>
						Logout
					</Button>
				</div>
			);
		}
	};

	return (
		<>
			<div className="sb-landing">
				<div
					className="sb-landing__header"
					style={{
						backgroundImage: `url(${landingData?.header_image || defaultHeader})`,
						backgroundSize: `auto 100%`,
						backgroundRepeat: "no-repeat",
						backgroundColor: "black",
					}}
				>
					<div className="sb-landing__header__inner">
						<div className="sb-landing__header__inner__head">{renderLoginButton()}</div>
						<div className="sb-landing__header__inner__body">
							<div className="sb-landing__header__inner__body__left">
								<div className="sb-landing__header__inner__body__left__title" style={{ zIndex: 3, marginBottom: 80 }}>
									<Link to="/">
										<FullLogoIcon />
									</Link>
								</div>
								<h1 className="sb-landing__header__inner__body__left__title">{landingData?.header_text}</h1>
								<p className="sb-landing__header__inner__body__left__subtitle">{landingData?.sub_text}</p>
							</div>
							<div className="sb-landing__header__inner__body__right">
								<div className="sb-landing__header__inner__body__right__card">
									<div className="sb-landing__header__inner__body__right__card__head">
										<h1 className="sb-landing__header__inner__body__right__card__head__title">
											{creatorProduct?.type === "free" ? "Included in your Membership" : "Membership"}
										</h1>
										<p className="sb-landing__header__inner__body__right__card__head__subtitle">
											Access our digital content powered by popular music to keep you sweating from home, when
											traveling, or when you just don't have time for class.
										</p>
									</div>
									{creatorProduct?.price && creatorProduct?.type !== "free" && (
										<div className="sb-landing__header__inner__body__right__card__price">
											<Heading11 className="sb-landing__header__inner__body__right__card__price__title">
												{formatMoney(creatorProduct.price)}
											</Heading11>
											<Span2 className="sb-landing__header__inner__body__right__card__price__time">/month</Span2>
											<Paragraph25 className="sb-landing__header__inner__body__right__card__price__cancel">
												Cancel any time
											</Paragraph25>
										</div>
									)}
									<CommonButton
										data-id="landing-get-started"
										uppercase
										onClick={() => navigate(`/signup/${gym}`)}
										className="primary-filled--branding-primary-color"
										style={{
											backgroundColor: domainGymData?.brand_color1 !== "" ? domainGymData?.brand_color1 : null,
										}}
									>
										<Paragraph5>Let's get started</Paragraph5>
									</CommonButton>
									<div className="sb-landing__header__inner__body__right__card__description">
										<div className="sb-landing__header__inner__body__right__card__description__item">
											<div
												style={{
													backgroundColor: domainGymData?.brand_color1 !== "" ? domainGymData?.brand_color1 : "#f2994a",
												}}
												className="sb-landing__header__inner__body__right__card__description__item__icon"
											>
												<img src={checkIcon} alt="check" />
											</div>
											<span className="sb-landing__header__inner__body__right__card__description__item__text">
												On Demand Workouts
											</span>
										</div>
										<div className="sb-landing__header__inner__body__right__card__description__item">
											<div
												style={{
													backgroundColor: domainGymData?.brand_color1 !== "" ? domainGymData?.brand_color1 : "#f2994a",
												}}
												className="sb-landing__header__inner__body__right__card__description__item__icon"
											>
												<img src={checkIcon} alt="check" />
											</div>
											<span className="sb-landing__header__inner__body__right__card__description__item__text">
												Live Workouts
											</span>
										</div>
										<div className="sb-landing__header__inner__body__right__card__description__item">
											<div
												style={{
													backgroundColor: domainGymData?.brand_color1 !== "" ? domainGymData?.brand_color1 : "#f2994a",
												}}
												className="sb-landing__header__inner__body__right__card__description__item__icon"
											>
												<img src={checkIcon} alt="check" />
											</div>
											<span className="sb-landing__header__inner__body__right__card__description__item__text">
												Access workouts from any device on the SWEATBASE App
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="sb-landing__characteristics">
					<div className="sb-landing__characteristics__item">
						<div className="sb-landing__characteristics__item__icon-container">
							<ComputerIcon color={domainGymData?.brand_color1 !== "" ? domainGymData?.brand_color1 : "#f2994a"} />
						</div>
						<h2 className="sb-landing__characteristics__item__title">Anytime Anywhere</h2>
						<p className="sb-landing__characteristics__item__text">
							Enjoy workouts from home using your smartphone, computer, tablet, TV, and more... by downloading the
							SWEATBASE App.
						</p>
					</div>
					<div className="sb-landing__characteristics__item">
						<div className="sb-landing__characteristics__item__icon-container">
							<MusicIcon color={domainGymData?.brand_color1 !== "" ? domainGymData?.brand_color1 : "#f2994a"} />
						</div>
						<h2 className="sb-landing__characteristics__item__title">Powerful Music</h2>
						<p className="sb-landing__characteristics__item__text">
							Our workouts paired with high energy playlists powered by FITRADIO for you to workout at the beat of the
							music.
						</p>
					</div>
					<div className="sb-landing__characteristics__item">
						<div className="sb-landing__characteristics__item__icon-container">
							<LiveIcon fill={domainGymData?.brand_color1 !== "" ? domainGymData?.brand_color1 : "#f2994a"} />
						</div>
						<h2 className="sb-landing__characteristics__item__title">Live or on Demand</h2>
						<p className="sb-landing__characteristics__item__text">
							Sign up for a live class, or enjoy hundreds of workouts on-demand any time of the day.
						</p>
					</div>
				</div>
				<div className="sb-landing__app">
					<div className="sb-landing__app__image">
						<img className="sb-landing__app__image__img" src={devicesImage} alt="devices" />
					</div>
					<div className="sb-landing__app__sources">
						<h1 className="sb-landing__app__sources__title">Download the SWEATBASE App!</h1>
						<p className="sb-landing__app__sources__subtitle">
							Gain access to all of our live and on-demand workouts paired with popular music.
						</p>
						<div className="sb-landing__app__sources__apps">
							<img src={googlePlayImage} alt="google play" />
							<img src={appStoreImage} alt="app store" />
						</div>
						<img className="sb-landing__app__sources__icons" src={iconsImage} alt="apps" />
					</div>
				</div>
			</div>
			<Footer className="sb-landing__footer">
				<a className="privacy-link" href="/privacy" target="_blank" rel="noreferrer">
					Privacy
				</a>
				<a className="terms-link" href="/tos" target="_blank" rel="noreferrer">
					Terms
				</a>
			</Footer>
		</>
	);
};

export default Landing;
