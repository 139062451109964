// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import notification from "antd/lib/notification";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import InputField from "components/UI/Form/InputField";
import Label from "components/UI/Form/label";
import { CREATE_ACCOUNT_FORM_ID } from "../constants";
import {
	validateEmail,
	confirmPassword,
	verifyPasswordNew,
	validatePhoneNumber,
} from "helpers/Form/commonFormValidations";
import { useCreateCustomerMutation, useSignUpMutation } from "store/api/api";
import { useSetRecoilState } from "recoil";
import { userAtom } from "../../../globalAtoms";
import { Navigate } from "react-router";
import { Button } from "components/Common/Button";
import { useDispatch } from "react-redux";
import { updateUserId } from "store/slices";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const CreateAccountForm = (props) => {
	const [signUpTrigger, signUpResponse] = useSignUpMutation();
	const setUser = useSetRecoilState(userAtom);
	const [createStripeCustomerTrigger, createStripeCustomerResponse] = useCreateCustomerMutation();
	const [conflict, setConflict] = useState("");
	const dispatch = useDispatch();

	const addStripeCustomerId = async (auth_token) => {
		try {
			const createStripeCustomerResponse = await createStripeCustomerTrigger({ auth_token });
			if (createStripeCustomerResponse.data) {
				return notification.success({
					message: "Customer ID Created",
				});
			}
			if (createStripeCustomerResponse.error) {
				notification.error({
					message: "Error Creating Customer ID!",
				});
			}
		} catch (error) {
			console.error("Error Creating Customer: ");
		}
	};

	const handleSignUp = async (formObj) => {
		const { email, password, phoneNumber } = formObj;
		setUser(null);
		try {
			// Trying to sign up should should also test login
			// Sign Up User
			const body = {
				email_address: email,
				password,
				phone_number: phoneNumber,
				first_name: "",
				last_name: "",
				registration_path: "web",
				campaign: "",
			};
			setConflict("");
			const signUpResponse = await signUpTrigger(body);
			if (signUpResponse.data) {
				notification.success({
					message: "Account Created!",
				});
				const { auth_token, user } = signUpResponse.data;
				if (auth_token && user) {
					localStorage.setItem("creator_jwt_token", auth_token);
					dispatch(updateUserId(user.id));
				}
				addStripeCustomerId(auth_token);
			}
			if (signUpResponse.error) {
				if (signUpResponse.error?.data?.error && signUpResponse.error?.status === 409) {
					setConflict(signUpResponse?.error?.data?.error);
					return;
				}
				notification.error({
					message: signUpResponse.error?.data?.error || "Error Creating Stripe Account",
				});
			}
		} catch (e) {
			return notification.error({
				message: "An error occurred signing you up",
			});
		}
	};

	const handleFormSubmit = async (formObj) => {
		handleSignUp(formObj);
	};

	const isLoading = signUpResponse.isLoading || createStripeCustomerResponse.isLoading;

	if (signUpResponse.isSuccess && createStripeCustomerResponse.isSuccess) {
		return <Navigate to="/creator-creation/your-information" />;
	}

	return (
		<Form onSubmit={handleFormSubmit}>
			{({ handleSubmit, values }) => {
				return (
					<form id={CREATE_ACCOUNT_FORM_ID} onSubmit={handleSubmit}>
						<Label>Email</Label>
						<Field name="email" validate={validateEmail}>
							{({ input, meta }) => (
								<InputField
									type="email"
									placeholder="Enter Email Address..."
									input={input}
									meta={{ ...meta, error: conflict?.includes("email") ? conflict : meta.error }}
								/>
							)}
						</Field>
						<Label>Phone Number</Label>
						<Field name="phoneNumber" validate={validatePhoneNumber}>
							{({ input, meta }) => (
								<InputField
									placeholder="Enter Phone Number..."
									type="text"
									input={input}
									meta={{ ...meta, error: conflict?.includes("phone") ? conflict : meta.error }}
									phone
								/>
							)}
						</Field>
						<Label>Password</Label>
						<Field type="password" name="password" validate={verifyPasswordNew}>
							{({ input, meta }) => (
								<InputField placeholder="Enter Password..." type="password" input={input} meta={meta} />
							)}
						</Field>
						<Label>Confirm Password</Label>
						<Field name="confirmPassword" validate={(v) => confirmPassword(v, values.password)}>
							{({ input, meta }) => (
								<InputField
									placeholder="Enter Password Again..."
									type="password"
									input={input}
									meta={{ ...meta, error: confirmPassword(values.confirmPassword, values.password) }}
								/>
							)}
						</Field>
						<Button
							type="submit"
							className="primary-filled"
							chevronRight
							uppercase
							loading={isLoading}
							disabled={isLoading}
						>
							Continue
						</Button>
					</form>
				);
			}}
		</Form>
	);
};

export default CreateAccountForm;
