// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import { userAtom } from "../../../globalAtoms";
import defaultHeader from "../../../images/default-header.jpeg";
import userIcon from "../../../images/user-icon.png";
// import broadcastSVG from '../../../images/broadcast.svg';

import { LiveIcon } from "components/Icons";
import {
	useGetDomainGymQuery,
	useGetPagesQuery,
	useGetScheduledStreamsQuery,
	useGetGymWorkoutsQuery,
} from "store/api/api";
import "./styles.scss";
import usePrimaryColor from "helpers/hooks/usePrimaryColor";
import { Button, ConfigProvider } from "antd";
import { VideoCameraOutlined } from "@ant-design/icons";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
function GymBanner() {
	const { gym } = useParams();
	const { data: domainGymData } = useGetDomainGymQuery(gym, { skip: !gym });
	const gymId = domainGymData?.id || null;
	const { data: scheduledStreams } = useGetScheduledStreamsQuery(gymId, { skip: !domainGymData });
	const { data: { workouts } = {} } = useGetGymWorkoutsQuery({ gym_id: gymId }, { skip: !domainGymData });

	const currentlyStreaming = scheduledStreams?.filter((stream) => {
		const workout = workouts?.find((workout) => workout.id === stream.workout_id);
		return stream?.currently_streaming && workout?.playback_id;
	});
	const primaryColor = usePrimaryColor();

	const { data: pageData } = useGetPagesQuery(gymId, { skip: !gymId });
	const navigate = useNavigate();
	const location = useLocation();
	const user = useRecoilValue(userAtom);
	//const headerImage = user?.pages?.[0]?.header_image;
	const [headerImage, setHeaderImage] = useState(defaultHeader);
	const gymName = user?.gym?.name;
	// TEMPORARY: Should switch to redux variable or route navigation
	// TODO: Add redux variable to track live status
	useEffect(() => {
		// Update the document title using the browser API
		document.title = `SWEATBASE${gymName?.length > 0 ? " - " + gymName : ""}`;
	});

	const handleOnDemandClick = () => {
		// if (location.pathname.includes("/dashboard/on-demand")) return;
		if (location.pathname.includes("/dashboard/consumer")) {
			navigate("../consumer/on-demand");
		} else {
			navigate("../on-demand");
		}
	};

	const handleLiveClick = () => {
		if (location.pathname.includes("/dashboard/consumer")) {
			navigate("../consumer/live");
		} else {
			navigate("../live");
		}
	};

	useEffect(() => {
		if (pageData) {
			const landingPage = pageData.find((page) => page.type === "landing");
			if (landingPage) {
				const headerImage = landingPage.header_image;
				setHeaderImage(headerImage);
			}
		}
	}, [pageData]);

	useEffect(() => {
		if (
			!location.pathname.includes("on-demand") &&
			!location.pathname.includes("live") &&
			user.user_type === "consumer"
		) {
			navigate("./on-demand");
		}
	}, [location, navigate, user.user_type]);

	return (
		<div
			className="sb-gym-banner-container"
			style={{
				backgroundImage: `url("${headerImage}")`,
			}}
		>
			<div className="sb-gym-banner-container__inner">
				<div className="sb-gym-banner-container__inner__gym-profile">
					<div className="sb-gym-banner-container__inner__gym-profile__gym"></div>
					{!location.pathname.includes("consumer") && (
						<div className="sb-gym-banner-container__inner__gym-profile__profile">
							<img className="sb-gym-banner-container__inner__gym-profile__profile__icon" src={userIcon} alt="user" />
							<span
								className="sb-gym-banner-container__inner__gym-profile__profile__text"
								onClick={() => navigate("../profile")}
							>
								My Profile
							</span>
						</div>
					)}
				</div>
				<div className="sb-gym-banner-container__inner__welcome">
					<div className="sb-gym-banner-container__inner__welcome__container">
						<h3 className="sb-gym-banner-container__inner__welcome__container__sub-title">Welcome To</h3>
						<h1 className="sb-gym-banner-container__inner__welcome__container__title">{domainGymData?.name}</h1>
						<div className="sb-gym-banner-container__inner__welcome__container__actions">
							<ConfigProvider
								theme={{
									hashed: false,
									token: {
										colorPrimary: primaryColor,
										borderRadius: 4,
										colorText: "#000",
										fontSize: 14,
									},
									components: {
										Button: {
											colorPrimary: primaryColor,
											textTransform: "none",
											fontSize: 14,
										},
									},
								}}
							>
								<Button type="primary" onClick={handleOnDemandClick}>
									<VideoCameraOutlined />
									On Demand
								</Button>
							</ConfigProvider>
							<button
								disabled={!currentlyStreaming?.length}
								onClick={handleLiveClick}
								className={"sb-livestream-button"}
							>
								<LiveIcon width={16} height={16} style={{ fontSize: 12 }} fill={primaryColor} />

								<span className="sb-gym-banner-container__inner__welcome__container__actions__live__text">Live</span>
								<div className={currentlyStreaming?.length ? "live-heart" : "icon"}></div>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GymBanner;
