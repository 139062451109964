import { jwtDecode } from "jwt-decode";

export const getLocalUser = () => {
	try {
		const token = localStorage.getItem("creator_jwt_token");
		const split = token.split(".")[1];

		return JSON.parse(atob(split));
	} catch (e) {
		return null;
	}
};

export const isTokenExpired = (token) => {
	try {
		const decoded = jwtDecode(token);

		if (decoded.exp) {
			const currentTime = Math.floor(Date.now() / 1000);
			return decoded.exp < currentTime;
		}

		return true;
	} catch (error) {
		return true;
	}
};

