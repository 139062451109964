import React from "react";

const ScheduledOnDemandIcon = (props) => (
	<svg
		width={`${props.width ?? 7}`}
		height={`${props.height ?? 10}`}
		viewBox="0 0 7 10"
		fill={props?.fill ?? "#FF865C"}
		xmlns="http://www.w3.org/2000/svg"
		data-id="ScheduledOnDemandIcon"
	>
		<path
			d="M6.50146 8.33331H6.0848V6.94456C6.08435 6.67417 5.99667 6.41114 5.8348 6.19456L4.93896 4.99998L5.8348 3.80581C5.99654 3.58916 6.0842 3.32618 6.0848 3.05581V1.66665H6.50146C6.61197 1.66665 6.71795 1.62275 6.79609 1.54461C6.87423 1.46647 6.91813 1.36049 6.91813 1.24998C6.91813 1.13947 6.87423 1.03349 6.79609 0.955352C6.71795 0.877212 6.61197 0.833313 6.50146 0.833313H0.668132C0.557625 0.833313 0.451644 0.877212 0.373504 0.955352C0.295364 1.03349 0.251465 1.13947 0.251465 1.24998C0.251465 1.36049 0.295364 1.46647 0.373504 1.54461C0.451644 1.62275 0.557625 1.66665 0.668132 1.66665H1.0848V3.0554C1.08525 3.32579 1.17292 3.58882 1.3348 3.8054L2.23063 4.99998L1.3348 6.19415C1.17306 6.4108 1.08539 6.67378 1.0848 6.94415V8.33331H0.668132C0.557625 8.33331 0.451644 8.37721 0.373504 8.45535C0.295364 8.53349 0.251465 8.63947 0.251465 8.74998C0.251465 8.86049 0.295364 8.96647 0.373504 9.04461C0.451644 9.12275 0.557625 9.16665 0.668132 9.16665H6.50146C6.61197 9.16665 6.71795 9.12275 6.79609 9.04461C6.87423 8.96647 6.91813 8.86049 6.91813 8.74998C6.91813 8.63947 6.87423 8.53349 6.79609 8.45535C6.71795 8.37721 6.61197 8.33331 6.50146 8.33331ZM1.91813 8.33331V6.94456C1.91846 6.85446 1.94767 6.76684 2.00146 6.69456L2.8973 5.49998C3.00504 5.35554 3.06324 5.18017 3.06324 4.99998C3.06324 4.81979 3.00504 4.64442 2.8973 4.49998L2.00146 3.3054C1.94753 3.23319 1.91831 3.14552 1.91813 3.0554V1.66665H5.25146V3.0554C5.25114 3.1455 5.22193 3.23312 5.16813 3.3054L4.2723 4.49998C4.16456 4.64442 4.10636 4.81979 4.10636 4.99998C4.10636 5.18017 4.16456 5.35554 4.2723 5.49998L5.16813 6.69498C5.22206 6.76719 5.25129 6.85485 5.25146 6.94498V8.33331H1.91813Z"
			fill={props?.fill ?? "#FF865C"}
		/>
	</svg>
);

export default ScheduledOnDemandIcon;

