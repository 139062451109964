// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";

// -----------------------------------------------------------------------------
// Components, utils and Styles
// -----------------------------------------------------------------------------
import { Button } from "components/Common/Button";
import { LoadingMask } from "components/Common/LoadingMask";
import { ImageInputCard } from "components/Common/Cards/ImageInputCard";
import { ColorPickerCard } from "components/Common/Cards/ColorPickerCard";
import { Paragraph2 } from "themes/default/_typography";
import StyledBrandingForm from "./styles";
import {
	useCreateGymAssetMutation,
	useCreateGymBrandingColorsMutation,
	useGetDomainGymQuery,
	useUpdateGymBrandingColorsMutation,
} from "store/api/api";
import { useParams } from "react-router";
import { notification } from "antd";
import { maxImageAssetSize, imageSizeLimitError } from "helpers/constants";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const BrandingForm = (props) => {
	const { gym } = useParams();
	const { data: domainGym, isLoading: gymSaving, refetch: domainGymRefetch } = useGetDomainGymQuery(gym);
	const gymId = domainGym?.id;
	const [selectedColor, setSelectedColor] = useState(domainGym?.brand_color1);
	const [selectedImageDark, setSelectedImageDark] = useState(domainGym?.dark_logo);
	const [selectedImageLight, setSelectedImageLight] = useState(domainGym?.light_logo);

	const [updateGymBrandingColorsTrigger] = useUpdateGymBrandingColorsMutation();
	const [createGymBrandingColorsTrigger] = useCreateGymBrandingColorsMutation();
	const [createGymAssetTrigger] = useCreateGymAssetMutation();

	useEffect(() => {
		setSelectedColor(domainGym?.brand_color1);
	}, [domainGym]);

	const updateGymBrandingColors = async (brandColor) => {
		// NOTE: USING GYMID & GYM_ID BECAUSE OF A BUG IN THE API
		// SEE GYMS_SERVICE EDIT BRANDING LINE 50 ("edit_gym_branding("${json.gymId}"")
		// VS GYMS_CONTROLLER EDIT BRANDING LINE 208 (return Promise.resolve({ response: { gym: result.gym_id }, status: 200 });)
		const { data, error } = await updateGymBrandingColorsTrigger({
			gym_id: gymId,
			color1: brandColor,
		});
		if (data) {
			domainGymRefetch();
			if (props.refetch) props.refetch();
			notification.success({
				message: "Brand color saved successfully",
			});
		}
		if (error) {
			notification.error({
				message: "Something went wrong updating your colors",
			});
		}
	};

	const createGymBrandingColors = async (brandColor) => {
		// NOTE: USING GYMID & GYM_ID BECAUSE OF A BUG IN THE API
		// SEE GYMS_SERVICE EDIT BRANDING LINE 50 ("edit_gym_branding("${json.gymId}"")
		// VS GYMS_CONTROLLER EDIT BRANDING LINE 208 (return Promise.resolve({ response: { gym: result.gym_id }, status: 200 });)
		const { data, error } = await createGymBrandingColorsTrigger({
			gym_id: gymId,
			color1: brandColor,
		});
		if (data) {
			domainGymRefetch();
			if (props.refetch) props.refetch();
		}
		if (error) {
			notification.error({
				message: "Something went wrong saving your colors",
			});
		}
	};

	const saveBrandingChanges = async () => {
		const darkImage = typeof selectedImageDark === "object" ? selectedImageDark : null;
		if (darkImage) {
			await saveBrandingImage(darkImage, "dark");
			setSelectedImageDark(null);
		}

		const lightImage = typeof selectedImageLight === "object" ? selectedImageLight : null;
		if (lightImage) {
			await saveBrandingImage(lightImage, "light");
			setSelectedImageLight(null);
		}
		const brandColor = domainGym?.brand_color1 !== selectedColor ? selectedColor : null;
		// NO CURRENT BRAND COLOR MUST CREATE INSTEAD OF UPDATE:
		if (!domainGym?.brand_color1 && selectedColor) {
			await createGymBrandingColors(selectedColor);
		}
		if (brandColor) {
			await updateGymBrandingColors(brandColor);
		}

		domainGymRefetch();
		if (props.refetch) props.refetch();
	};

	const saveBrandingImage = async (image, type) => {
		const jwt = localStorage.getItem("creator_jwt_token");
		const formData = new FormData();
		formData.append("image", image);
		formData.append("gym_id", gymId);
		formData.append("type", type);
		const { data, error } = await createGymAssetTrigger({ formData, jwt });
		if (data) {
			notification.success({
				message: "Image saved successfully",
			});
		}
		if (error) {
			const message =
				error?.data?.message === "send a file"
					? "Branding Image is required"
					: error?.data?.message || "Something went wrong saving your image";
			notification.error({
				message,
			});
		}
	};

	return (
		<StyledBrandingForm>
			<div className="sb-branding-form">
				<ImageInputCard
					title="Logo dark version"
					subtitle="Your full logo appears on your website, app sign up pages, and more."
					description="PNG or JPEG Recommended size of 250x250 px | Max 3 MB"
					imageUrl={domainGym?.dark_logo || ""}
					onSaveImage={setSelectedImageDark}
					onCancel={() => setSelectedImageDark(null)}
					sizeLimit={{
						limit: maxImageAssetSize,
						error: imageSizeLimitError,
					}}
				/>
				<ImageInputCard
					title="Logo light version"
					subtitle="Your full logo appears on your website, app sign up pages, and more."
					description="PNG or JPEG Recommended size of 250x250 px | Max 3 MB"
					imageUrl={domainGym?.light_logo || ""}
					onSaveImage={setSelectedImageLight}
					onCancel={() => setSelectedImageLight(null)}
					sizeLimit={{
						limit: maxImageAssetSize,
						error: imageSizeLimitError,
					}}
				/>
				<ColorPickerCard
					title="Brand Color"
					subtitle="Your brand color will be the accent color on your app and website. Appears on buttons, callouts, etc."
					selectedColor={selectedColor}
					onChangeColor={setSelectedColor}
				/>
				<Button className="primary-filled" onClick={saveBrandingChanges} saveIcon uppercase>
					<Paragraph2>Save Changes</Paragraph2>
				</Button>
			</div>
			{gymSaving && <LoadingMask />}
		</StyledBrandingForm>
	);
};

export default BrandingForm;
