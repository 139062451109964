import styled from "styled-components";

const StyledTags = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	overflow: hidden;

	.sb-tags {
		&__tag {
			padding: 4px 6px;
			height: 23px;
			background: rgba(230, 230, 235, 0.5);
			border-radius: 100px;
			white-space: nowrap;
			text-overflow: ellipsis;
			max-width: 133px;
			font-size: 12px;
			border: 1px solid #d9d9d9;
			text-align: center;
		}
	}
	.more-tag {
		border-radius: 100px;
		text-align: center;
	}
`;

export default StyledTags;
