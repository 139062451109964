import { Flex, Layout, Typography, Dropdown, Modal, Avatar } from "antd";
import { isUserCreator } from "constants/ui";
import usePrimaryColor from "helpers/hooks/usePrimaryColor";
import { useState } from "react";
import UpdatePassword from "./UpdatePassword";
import useLogout from "utils/useLogout";
import { useParams } from "react-router-dom";
import { useGetDomainGymQuery } from "store/api/api";

import "./overrides.scss";
import styles from "./styles.module.scss";
import alpha from "helpers/alpha";
import EditProfile from "components/EditProfile";
import { EditOutlined } from "@ant-design/icons";

const { Header: AntHeader } = Layout;
const { Title } = Typography;

const getName = (navbarState) => {
	switch (navbarState) {
		case "home":
			return "Home";
		case "workouts":
			return "Workouts";
		case "members":
			return "Members";
		case "earnings":
			return "Earnings";
		case "analytics":
			return "Analytics";
		case "consumer":
			return "Consumer View";
		case "pricing-plan":
			return "Payments & Pricing";
		case "settings":
			return "Settings";
		default:
			return "Home";
	}
};

function Header({ userType, navbarState, user }) {
	const isCreator = isUserCreator(userType);
	const primaryColor = usePrimaryColor();
	const [openResetPassword, setOpenResetPassword] = useState(false);
	const [openEditProfile, setOpenEditProfile] = useState(false);
	const { gym } = useParams();
	const domainGymResponse = useGetDomainGymQuery(gym);
	const logoutHook = useLogout({}, gym);
	if (!isCreator) {
		return null;
	}
	const getInitials = (name) => {
		if (!name) return "";
		const words = name.split(" ");
		if (words.length === 1) return words[0].charAt(0).toUpperCase();
		return words.map((word) => word.charAt(0).toUpperCase()).join("");
	};

	const gymName = getInitials(domainGymResponse?.data?.name);
	const items = [
		{
			key: "1",
			disabled: true,
			style: { cursor: "default" },
			label: (
				<div style={{ width: "100%" }}>
					<Title title={domainGymResponse?.data?.name} className={styles.menuHead} level={4}>
						{domainGymResponse?.data?.name}
					</Title>
					<Typography.Paragraph title={user?.email_address} ellipsis className={styles.menuEmail}>
						{user?.email_address}
					</Typography.Paragraph>
				</div>
			),
		},
		{
			key: "2",
			onClick: () => setOpenResetPassword(true),
			label: (
				<Flex gap="16px">
					<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g clipPath="url(#clip0_410_4093)">
							<path
								d="M18.8333 8.75H20.8333C21.0985 8.75 21.3528 8.85536 21.5404 9.04289C21.7279 9.23043 21.8333 9.48478 21.8333 9.75V21.75C21.8333 22.0152 21.7279 22.2696 21.5404 22.4571C21.3528 22.6446 21.0985 22.75 20.8333 22.75H4.83325C4.56804 22.75 4.31368 22.6446 4.12615 22.4571C3.93861 22.2696 3.83325 22.0152 3.83325 21.75V9.75C3.83325 9.48478 3.93861 9.23043 4.12615 9.04289C4.31368 8.85536 4.56804 8.75 4.83325 8.75H6.83325V7.75C6.83325 6.1587 7.46539 4.63258 8.59061 3.50736C9.71583 2.38214 11.242 1.75 12.8333 1.75C14.4246 1.75 15.9507 2.38214 17.0759 3.50736C18.2011 4.63258 18.8333 6.1587 18.8333 7.75V8.75ZM5.83325 10.75V20.75H19.8333V10.75H5.83325ZM11.8333 14.75H13.8333V16.75H11.8333V14.75ZM7.83325 14.75H9.83325V16.75H7.83325V14.75ZM15.8333 14.75H17.8333V16.75H15.8333V14.75ZM16.8333 8.75V7.75C16.8333 6.68913 16.4118 5.67172 15.6617 4.92157C14.9115 4.17143 13.8941 3.75 12.8333 3.75C11.7724 3.75 10.755 4.17143 10.0048 4.92157C9.25468 5.67172 8.83325 6.68913 8.83325 7.75V8.75H16.8333Z"
								fill="currentColor"
							/>
						</g>
						<defs>
							<clipPath id="clip0_410_4093">
								<rect width="24" height="24" fill="white" transform="translate(0.833252 0.75)" />
							</clipPath>
						</defs>
					</svg>

					<Typography.Text className={styles.resetPassword}>Reset Password</Typography.Text>
				</Flex>
			),
		},
		{
			key: "4",
			onClick: () => setOpenEditProfile(true),
			label: (
				<Flex gap="16px">
					<EditOutlined style={{ fontSize: 24 }} />
					<Typography.Text className={styles.resetPassword}>Edit Profile</Typography.Text>
				</Flex>
			),
		},
		{
			key: "3",
			onClick: logoutHook.logout,
			label: (
				<Flex style={{ color: primaryColor }} gap="16px">
					<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M12.8333 21.7501C11.0544 21.7485 9.31587 21.2198 7.83729 20.2308C6.3587 19.2417 5.20637 17.8367 4.52583 16.1932C3.84529 14.5496 3.66708 12.7412 4.01369 10.9964C4.3603 9.25165 5.2162 7.64871 6.47327 6.39007C6.54222 6.31932 6.62464 6.26308 6.71566 6.22468C6.80669 6.18629 6.90448 6.1665 7.00327 6.1665C7.10207 6.1665 7.19986 6.18629 7.29089 6.22468C7.38191 6.26308 7.46433 6.31932 7.53327 6.39007C7.67372 6.5307 7.75261 6.72132 7.75261 6.92007C7.75261 7.11882 7.67372 7.30945 7.53327 7.45007C6.79641 8.13669 6.20538 8.9647 5.79546 9.88469C5.38554 10.8047 5.16513 11.7978 5.14736 12.8048C5.12959 13.8119 5.31484 14.8122 5.69205 15.746C6.06926 16.6799 6.6307 17.5283 7.34289 18.2405C8.05508 18.9526 8.90342 19.5141 9.8373 19.8913C10.7712 20.2685 11.7715 20.4538 12.7785 20.436C13.7855 20.4182 14.7787 20.1978 15.6987 19.7879C16.6186 19.378 17.4467 18.7869 18.1333 18.0501C18.8311 17.3551 19.3848 16.5291 19.7627 15.6196C20.1405 14.7101 20.335 13.7349 20.335 12.7501C20.335 11.7652 20.1405 10.79 19.7627 9.88051C19.3848 8.971 18.8311 8.14505 18.1333 7.45007C17.9928 7.30945 17.9139 7.11882 17.9139 6.92007C17.9139 6.72132 17.9928 6.5307 18.1333 6.39007C18.2022 6.31932 18.2846 6.26308 18.3757 6.22468C18.4667 6.18629 18.5645 6.1665 18.6633 6.1665C18.7621 6.1665 18.8599 6.18629 18.9509 6.22468C19.0419 6.26308 19.1243 6.31932 19.1933 6.39007C20.4504 7.64871 21.3062 9.25165 21.6529 10.9964C21.9995 12.7412 21.8213 14.5496 21.1407 16.1932C20.4602 17.8367 19.3079 19.2417 17.8293 20.2308C16.3507 21.2198 14.6122 21.7485 12.8333 21.7501Z"
							fill="currentColor"
						/>
						<path
							d="M12.8333 13.5C12.6351 13.4974 12.4459 13.4176 12.3058 13.2775C12.1657 13.1374 12.0858 12.9481 12.0833 12.75V4.75C12.0833 4.55109 12.1623 4.36032 12.3029 4.21967C12.4436 4.07902 12.6343 4 12.8333 4C13.0322 4 13.2229 4.07902 13.3636 4.21967C13.5042 4.36032 13.5833 4.55109 13.5833 4.75V12.75C13.5807 12.9481 13.5008 13.1374 13.3607 13.2775C13.2206 13.4176 13.0314 13.4974 12.8333 13.5Z"
							fill="currentColor"
						/>
					</svg>

					<Typography.Text style={{ color: primaryColor }} className={styles.menuEmail}>
						Logout
					</Typography.Text>
				</Flex>
			),
		},
	];
	return (
		<>
			<AntHeader className={styles.navbar}>
				<Flex gap="middle" align="middle" justify="space-between" vertical={false}>
					<Title>{getName(navbarState)}</Title>
					<Dropdown
						trigger={["click"]}
						key={1}
						menu={{ items }}
						style={{ padding: 24, width: 228 }}
						overlayClassName="header-menu"
						placement="bottom"
					>
						<Avatar
							style={{
								color: primaryColor,
								fontWeight: 700,
								backgroundColor: alpha(primaryColor, 0.3),
								cursor: "pointer",
							}}
						>
							{gymName}
						</Avatar>
					</Dropdown>
				</Flex>
			</AntHeader>
			<Modal
				title={<Typography.Title level={3}>Update Password</Typography.Title>}
				open={openResetPassword}
				onCancel={() => setOpenResetPassword(false)}
				maskClosable={false}
				closable
				style={{ "--primary-color": primaryColor }}
				footer={[]}
				destroyOnClose
			>
				<UpdatePassword open={openResetPassword} onCancel={() => setOpenResetPassword(false)} />
			</Modal>
			<Modal
				open={openEditProfile}
				footer={null}
				title={<Typography.Title level={3}>Edit Profile</Typography.Title>}
				onCancel={() => setOpenEditProfile(false)}
				destroyOnClose
				maskClosable={false}
			>
				<EditProfile onCancel={() => setOpenEditProfile(false)} />
			</Modal>
		</>
	);
}

export default Header;

