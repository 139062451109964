import React from "react";
import FullScreenWorkoutVideoMusicPlayer from "../../../components/Workouts/FullScreenWorkoutVideoMusicPlayer";

function ViewWorkout(props) {
	return (
		<div>
			<FullScreenWorkoutVideoMusicPlayer {...props} />
		</div>
	);
}

export default ViewWorkout;
