import React, { useState, useRef } from "react";
import Modal from "antd/lib/modal";
import notification from "antd/lib/notification";
import { Button } from "components/Common/Button";
import { Paragraph3, Paragraph2 } from "themes/default/_typography";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "components/Icons";

import { selectImageCropAspectRatio } from "store/selectors";
import { updateImageCropAspectRatio } from "store/slices";
import { decimalToFraction, minimumPixelSize } from "utils";

import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

const CropModal = (props) => {
	const { localFile, onSave, onCancel, updateFileAddedLocally, sizeLimit, open, handleClose } = props;
	const cropperRef = useRef(null);
	const [croppedBlob, setCroppedBlob] = useState(null);
	const onCrop = () => {
		const imageElement = cropperRef?.current;
		const cropper = imageElement?.cropper;
		const canvas = cropper.getCroppedCanvas();
		canvas.toBlob((blob) => {
			if (blob === null) {
				return;
			} else {
				setCroppedBlob(blob);
			}
		}, "image/jpeg");
	};
	const dispatch = useDispatch();
	const aspectRatio = useSelector(selectImageCropAspectRatio);

	const handleCancel = () => {
		//Should dispatch action to close modal
		handleClose();
		dispatch(updateImageCropAspectRatio(null));
		if (onCancel) {
			onCancel();
		}
	};

	const handleSave = () => {
		if (sizeLimit?.limit && croppedBlob?.size > sizeLimit?.limit) {
			setCroppedBlob(null);
			notification.error(sizeLimit?.error);
		} else {
			const preview = URL.createObjectURL(croppedBlob);
			updateFileAddedLocally(preview);
			onSave(croppedBlob);
		}
		handleClose();
	};

	const { numerator: width, denominator: height } = minimumPixelSize(aspectRatio || 5 / 3);
	const minimumSize = `${width}px X ${height}px`;

	return (
		<Modal
			afterClose={() => {}}
			destroyOnClose={true}
			className="sb-modal"
			title="Crop Your Image"
			open={open}
			closeIcon={<CloseIcon data-id="close-category-modal" />}
			maskClosable={false}
			onCancel={handleCancel}
			data-id="img-crop-modal"
			footer={[
				<div key="create" className="sb-create-category-modal__footer">
					<Button data-id="sb-modal-cancel" className="primary-outlined primary-outlined--delete" onClick={handleCancel}>
						<Paragraph3>Cancel</Paragraph3>
					</Button>

					<Button className="primary-filled" onClick={handleSave} data-id="sb-modal-confirm">
						<Paragraph3>Save</Paragraph3>
					</Button>
				</div>,
			]}
		>
			<div className="sb-create-category-modal" data-id="category-modal">
				<Paragraph2>Cropping Ratio: {decimalToFraction(aspectRatio || 5 / 3)}</Paragraph2>
				<Paragraph2>Minimum size: {minimumSize}</Paragraph2>
				<Cropper
					src={localFile}
					crop={onCrop}
					ref={cropperRef}
					aspectRatio={aspectRatio}
					style={{
						height: 300,
						width: "100%",
						backgroundColor: "white",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
				/>
			</div>
		</Modal>
	);
};

export default CropModal;

