import { Navigate, useParams } from "react-router-dom";
import { useGetDomainGymQuery } from "store/api/api";
import LandingPage from "components/Microsites/LandingExample/Landing";
import { LoadingMask } from "components/Common/LoadingMask";
import NotFound from "pages/NotFound";
import { userAtom } from "../../globalAtoms";
import { useRecoilValue } from "recoil";

function Landing(props) {
	const { gym } = useParams();
	const user = useRecoilValue(userAtom);
	const { data, isLoading } = useGetDomainGymQuery(gym);
	if (user) {
		return <Navigate to={`/${gym}/dashboard`} />;
	}

	if (isLoading) {
		return <LoadingMask />;
	}

	if (data?.id) {
		return (
			<div>
				<LandingPage gymId={data.id} preview={props?.preview} />
			</div>
		);
	} else {
		return <NotFound />;
	}
}

export default Landing;
