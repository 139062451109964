// -------------------------------------------------------------------------------
// Libraries
// -------------------------------------------------------------------------------
import React from "react";
import { Select, Form } from "antd";
import usePrimaryColor from "helpers/hooks/usePrimaryColor";
// -------------------------------------------------------------------------------
// Utils, Styles and Assets
// -------------------------------------------------------------------------------
import StyledFormInput from "./styles";

// -------------------------------------------------------------------------------
// Component
// -------------------------------------------------------------------------------
const FormSelect = ({ name, label, placeholder, rules, options, className, dark, disabled, ...rest }) => {
	const { Option } = Select;
	const primaryColor = usePrimaryColor();

	return (
		<StyledFormInput
			style={{ "--primary-color": primaryColor }}
			className={dark ? "sb-form-select__dark" : `${className} sb-form-select`}
		>
			<Form.Item name={name} label={label} rules={rules} required={false}>
				<Select popupClassName="sb-dropdown-menu" size="large" placeholder={placeholder} disabled={disabled} {...rest}>
					{options.map((o, i) => (
						<Option key={`form-select-${name}-option-${i}`} label={o.label} disabled={!!o.disabled} value={o.value}>
							{o.label}
						</Option>
					))}
				</Select>
			</Form.Item>
		</StyledFormInput>
	);
};

export default FormSelect;

