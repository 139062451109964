import React from "react";
import { FullLogoIcon } from "components/Icons/FullLogoIcon";
import StyledHeader from "./styles";
import { Link } from "react-router-dom";

const Header = ({ actionsSection }) => {
	return (
		<StyledHeader className="sb-header">
			<div className="sb-header__logo-section">
				<Link to="/">
					<FullLogoIcon />
				</Link>
			</div>
			<div className="sb-header__actions-section">{actionsSection}</div>
		</StyledHeader>
	);
};

export default Header;
